import {
  createKeyRequest,
  getAllEvent,
  getAllRoomBooking,
  getSingleKeyRequest,
  handleKeyDown,
  updateKeyRequest,
} from "_services";
import { CommonInput, CustomSelect, Heading } from "components";
import { BasicExample } from "components/common/CommonModal/CommonModal";
import { errorSchema, utils } from "helper";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const CreateKeyRequest = () => {
  const initi = {
    event: "",
    room_booking: "",
    guest_name: "",
    mobile_number: "",
    reason: "",
    verification_code: "",
    is_verified: false,
  };

  const eventRef = useRef(null);
  const roomRef = useRef(null);
  const mobileRef = useRef(null);
  const nameRef = useRef(null);
  const resonRef = useRef(null);
  const navigate = useNavigate();
  const { id, eventView } = useParams();
  const [form, setForm] = useState(initi);
  const [initialForm, setInitialForm] = useState(initi);
  const [formError, setFormError] = useState("");
  const [allEventList, setAllEventList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [allRoomlist, setAllRoomList] = useState([]);
  const [idVerify,setIdVrify] = useState(id) 


  useEffect(() => {
    if (roomRef.current) {
      roomRef.current.focus();
    }
  }, []);

  useEffect(() => {
    getEventList(id);
  }, []);

  useEffect(() => {
    if (id) {
      getKeyRequestDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (form?.event && form?.room_booking ) {
      getRoomBookingList(form?.event, form?.room_booking);
    }
  }, [form?.room_booking,form?.event]);

  const getRoomBookingList = async () => {
 
    try {
      const res = await getAllRoomBooking({
        event_id: Number(form?.event),
        room_number: Number(form?.room_booking),
      });

      if (res?.status === 200) {
        setAllRoomList(res.results[0]);
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const getEventList = async (id) => {
    try {
      const res = await getAllEvent({ limit: "all" });

      if (res?.status === 200) {
        const filterEvent = res.results?.find((i) => i?.is_active === true);
        setAllEventList([
          {
            ...filterEvent,
            label: filterEvent?.event_name,
            value: filterEvent?.value,
          },
        ]);
        if(!id){
          setForm({ ...form, event: filterEvent?.id });
        }
      
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.keyRequestSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const getKeyRequestDetails = async (id) => {
    try {
      const res = await getSingleKeyRequest(id);

      if (res?.status === 200) {
       
         setForm((pre)=>({
          ...pre,
          event: res?.results.event ? res?.results?.event : "",
          room_booking: res?.results.room_booking ? res?.results?.room_booking : "",
          guest_name: res?.results.guest_name ? res?.results?.guest_name : "",
          mobile_number: res?.results.mobile_number ? res?.results?.mobile_number : "",
          reason: res?.results.reason ? res?.results?.reason : "",
          verification_code: res?.results.verification_code ? res?.results?.verification_code : "",
          is_verified: res?.results.is_verified ,
        }));
        setIdVrify(res?.results?.id)
      }
    } catch (error) {}
  };

  const createPayload = () => {
    return Object.keys(form).reduce((acc, key) => {
      if (form[key] !== initialForm[key]) {
        acc[key] = key === "room_booking" ? Number(form[key]) : form[key];
      }
      return acc;
    }, {});
  };

  const handleKeyRequest = async () => {
    const validationResult = await utils?.checkFormError(
      form,
      errorSchema.keyRequestSchema
    );

    if (validationResult === true) {
      setIsLoading(true);
      try {
        let res;
        const payload = createPayload();

        if (id) {
          res = await updateKeyRequest({ payload, id });
        } else {
          res = await createKeyRequest(payload);
        }

        if (res.status === 200 || res.status === 201) {
          toast.success(res.message);
          setForm({
            ...form,
            verification_code: res.results.verification_code,
            is_verified: res.results.is_verified,
          });
          setIdVrify(res.results.id);
        } else {
          toast.error(res.error);
        }
      } catch (error) {
        toast.error("An error occurred");
      } finally {
        setIsLoading(false);
      }
    } else {
      setFormError(validationResult);
    }
  }
  const updateVerification = async(idVerify)=>{
const payload = {
  is_verified:!form?.is_verified
}

    try {
      const res = await updateKeyRequest({
        payload,
        id:idVerify,
      });

      if (res.status === 200) {
        setIsLoading(false);
        toast.success(res.message);
        setForm({
          ...form,
          is_verified: res?.results?.is_verified,
        });
      } else {
        setForm({
          ...form,
          is_verified: false,
        });
        toast.error(res.error);
      }
    } catch (error) {
      
    }
  }

  const handleKey = (e, nextInputRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextInputRef) {
        nextInputRef.current.focus();
      } else {
      }
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  return (
    <div>
      <Heading title={"Add Key Request"} />
      <div className="_addContainer bg-[#FBF9FB]">
        <div className="_midCont_body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10 col-lg-10 col-xl-8">
                <div className="_crtDisp_listBx">
                  <div className="">
                    <h4 className="_disPatch_heads">Event Details</h4>
                    <div className="_fleX_inds">
                      <div className="_divsix">
                        <div className="singleDiv">
                          <CustomSelect
                            placeholder="Select Event"
                            label="Event"
                            options={allEventList}
                            onSelect={(data) => {
                              handleOnChange("event", data.value);
                            }}
                            focus={
                              !!(
                                typeof formError === "object" &&
                                formError?.event
                              )
                            }
                            error={
                              !!(typeof formError === "object")
                                ? formError?.event
                                : ""
                            }
                            value={allEventList?.find(
                              (i) => i?.id === form?.event
                            )}
                            disabled={true}
                            ref={eventRef}
                            enableSearch={true}
                            onEnterSelect={() => mobileRef.current.focus()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <h4 className="_disPatch_heads">Room Details</h4>
                    <div className="_fleX_inds">
                      <div className="_divsix">
                        <CommonInput
                          type="text"
                          placeholder=""
                          label="Room"
                          name="room_booking"
                          value={form.room_booking}
                          focus={
                            !!(
                              typeof formError === "object" &&
                              formError?.room_booking
                            )
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.room_booking
                              : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          ref={roomRef}
                          onKeyDown={(e) => handleKey(e, nameRef)}
                          disabled={eventView}
                          onKeyPress={handleKeyDown}
                        />
                      </div>
                      <div className="_divsix">
                        <CommonInput
                          type="text"
                          label="Name"
                          placeholder="Enter Name"
                          name="guest_name"
                          value={form?.guest_name}
                          onChange={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                          focus={!!formError?.guest_name}
                          error={formError.guest_name}
                          ref={nameRef}
                          onKeyDown={(e) => handleKey(e, mobileRef)}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divsix">
                        <CommonInput
                          type="text"
                          label="Mobile"
                          placeholder="Enter Mobile Number"
                          name="mobile_number"
                          value={form?.mobile_number}
                          onChange={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                          focus={!!formError?.mobile_number}
                          error={formError.mobile_number}
                          ref={mobileRef}
                          onKeyDown={(e) => handleKey(e, resonRef)}
                          disabled={eventView}
                          onKeyPress={handleKeyDown}
                          maxLength={10}
                        />
                      </div>
                      <div className="_divsix">
                        <CommonInput
                          type="text"
                          label="Reason"
                          placeholder="Enter Mobile Number"
                          name="reason"
                          value={form?.reason}
                          onChange={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                          focus={!!formError?.reason}
                          error={formError.reason}
                          ref={resonRef}
                          // onKeyDown={(e) => handleKey(e, nameRef)}
                          disabled={eventView}
                        />
                      </div>
                      {form?.guest_name && form?.mobile_number.length==10 && (
                        <>
                        {!form?.verification_code && <div className="_divsix">
                          <div className="">
                            <button
                              className="_addComm_btn"
                              type="button"
                              onClick={() => handleKeyRequest()}
                              disabled={isLoading}
                            >
                              {isLoading ? (
                                <BasicExample />
                              ) : (
                                "Create Key Request"
                              )}
                            </button>
                          </div>
                        </div>}
                        </>
                      )}
                    </div>
                  </div>
                 {form?.verification_code &&  <div className="">
                    <h4 className="_disPatch_heads">Verification Details</h4>
                    <div className="_fleX_inds">
                      <div className="_divsix">
                        <div className="singleDiv">
                          <CommonInput
                            type="text"
                            label="verification Code"
                            name="reason"
                            value={form?.verification_code}
                            onChange={({ target: { name, value } }) => {
                              handleOnChange(name, value);
                            }}
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="_divsix">
                        <div
                          className="_anvSwitcherNew form-check form-switch"
                          style={{ width: "100%" }}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="actStatus"
                            id={`actStatus-${form?.is_verified}`}
                            autoComplete="off"
                            checked={form?.is_verified}
                            onChange={() =>{
                              setForm((pre)=>({...pre,is_verified:!form?.is_verified}))
                              updateVerification(idVerify)}}
                            readOnly
                            // disabled={form?.is_verified}
                            
                          />
                          <span
                            className={
                              form?.is_verified
                                ? "active_label_masters_status"
                                : "disabled_label_masters_status"
                            }
                          >
                            {form?.is_verified ? "Verified" : "Not Verified"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>
              </div>
              {allRoomlist && <EventCard data={allRoomlist} />}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bottomFix">
        <button
          className="_addComm_btn"
          type="button"
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          {isLoading ? <BasicExample /> : "Add Key Request"}
        </button>
      </div> */}
    </div>
  );
};

export default CreateKeyRequest;

const EventCard = ({ data }) => {
  const styles = {
    card: {
      border: "1px solid #ccc",
      borderRadius: "8px",
      padding: "16px",
      maxWidth: "400px",
      margin: "16px auto",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    title: {
      fontSize: "20px",
      marginBottom: "8px",
    },
    subtitle: {
      fontSize: "16px",
      color: "#555",
      marginBottom: "16px",
    },
    section: {
      marginBottom: "16px",
    },
  };

  const {
    event_name,
    check_in,
    check_out,
    total_guests,
    special_requests,
    floor_name,
    room_number,
    guest,
  } = data;

  return (
    <div style={styles.card}>
      <h2 style={styles.title}>{event_name}</h2>
      <p style={styles.subtitle}>
        Room {room_number} - {floor_name}
      </p>

      <div style={styles.section}>
        <h3>Guest Information</h3>
        <p>
          <strong>Name:</strong> {guest?.name}
        </p>
        <p>
          <strong>Email:</strong> {guest?.email}
        </p>
        <p>
          <strong>Mobile:</strong> {guest?.mobile_number}
        </p>
        <p>
          <strong>Address:</strong> {guest?.address}, {guest?.city},{" "}
          {guest?.state}
        </p>
        <p>
          <strong>WhatsApp:</strong> {guest?.is_whatsapp_number ? "Yes" : "No"}
        </p>
      </div>

      <div style={styles.section}>
        <h3>Booking Details</h3>
        <p>
          <strong>Check-In:</strong> {new Date(check_in).toLocaleString()}
        </p>
        <p>
          <strong>Check-Out:</strong>{" "}
          {check_out ? new Date(check_out).toLocaleString() : "N/A"}
        </p>
        <p>
          <strong>Total Guests:</strong> {total_guests}
        </p>
        <p>
          <strong>Special Requests:</strong> {special_requests || "None"}
        </p>
      </div>
    </div>
  );
};
