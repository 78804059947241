// src/services/authService.js
import { createAsyncThunk } from '@reduxjs/toolkit';
import { API, axiosInstance } from 'lib';

export const createEvent = createAsyncThunk(
    'create event',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(API.EVENT, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);

export const getEvent = createAsyncThunk(
    'All event',
    async (data = {}, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.get(API.EVENT, data);
            return response.data;
        } catch (err) {
            return rejectWithValue(err.response.data);
        }
    }
);








