import { handleKeyDown, uploadFiles } from "_services";
import {
  createGuest,
  getSingleGuest,
  updateGuest,
} from "_services/guestServices";
import { CommonInput, CustomSelect, Heading } from "components";
import { BasicExample } from "components/common/CommonModal/CommonModal";
import DocumentImages from "components/common/DocumentImage";
import { errorSchema, utils } from "helper";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const CreateGuest = () => {
  const userTypeList = [
    { label: "ADMIN", value: 1 },
    { label: "GUEST", value: 2 },
    { label: "STAFF", value: 3 },
  ];

  const genderList = [
    { label: "MALE", value: 1 },
    { label: "FEMALE", value: 2 },
  ];

  const documentypeList = [
    { label: "Aadhar Card", value: 1 },
    { label: "Pan Card", value: 2 },
    { label: "Voter ID", value: 3 },
    { label: "Driving License", value: 4 },
    { label: "Passport", value: 5 },
  ];

  const [form, setForm] = useState({
    name: "",
    email: "",
    mobile_number: "",
    user_type: "",
    gender: "",
    state: "",
    city: "",
    address: "",
  });
  const [initialForm, setInitialForm] = useState({
    name: "",
    email: "",
    mobile_number: "",
    user_type: "",
    gender: "",
    state: "",
    city: "",
    address: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id, eventView } = useParams();
  const nameRef = useRef();
  const emailRef = useRef();
  const mobileRef = useRef();
  const userRef = useRef();
  const genderRef = useRef();
  const stateRef = useRef();
  const cityRef = useRef();
  const addressRef = useRef();
  const dropdownRef = useRef(null);
  const typeRef = useRef();
  const numberRef = useRef();
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [initialDocument, setInitialDocument] = useState({
    document_type: "",
    document_number: "",
    document_file: "",
  });
  const [documentDetails, setDocumentDetails] = useState({
    document_type: "",
    document_number: "",
    document_file: "",
  });

  useEffect(() => {
    if (id) {
      getEventDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  const getEventDetails = async (id) => {
    try {
      const res = await getSingleGuest(id);
      if (res?.status === 200) {
        const eventData = {
          name: res?.results?.name,
          email: res?.results?.email,
          mobile_number: res?.results?.mobile_number,
          user_type: res?.results?.user_type,
          gender: res?.results?.gender,
          state: res?.results?.state,
          city: res?.results?.city,
          address: res?.results?.address,
        };
        setForm(eventData);
        setInitialForm(eventData);
        setDocumentDetails({
          ...documentDetails,
          document_file: res?.results?.document_file,
          document_number: res?.results?.document_number,
          document_type: res?.results?.document_type,
        });
        setInitialDocument({
          ...documentDetails,
          document_file: res?.results?.document_file,
          document_number: res?.results?.document_number,
          document_type: res?.results?.document_type,
        })
      }
    } catch (error) {}
  };

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.guestSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  // const handleSubmit = async () => {
  //   const validationResult = await utils?.checkFormError(
  //     form,
  //     errorSchema.guestSchema
  //   );
  //   const payload = {};

  //   Object.keys(form).map((item, index) => {
  //     if (form[item] !== initialForm[item]) {
  //       payload[item] = form[item];
  //     }
  //   });

  //   if (validationResult === true) {
  //     try {
  //       setIsLoading(true);
  //       if (id) {
  //         const res = await updateGuest({
  //           payload,
  //           id,
  //         });

  //         if (res.status === 200) {
  //           setIsLoading(false);
  //           toast.success(res.message);
  //           navigate("/guest");
  //         } else {
  //           toast.error(res.error);
  //         }
  //       } else {
  //         const res = await createGuest(payload);
  //         if (res.status === 201) {
  //           setIsLoading(false);
  //           toast.success(res.message);
  //           navigate("/guest");
  //         } else {
  //           console.log(res);
  //           // toast.error(res.error);
  //         }
  //       }
  //     } catch (error) {
  //       setIsLoading(false);
  //     }
  //   } else {
  //     setFormError(validationResult);
  //   }
  // };

  const handleSubmit = async () => {
    // Run validation on the form fields
    const validationResult = await utils?.checkFormError(form, errorSchema.guestSchema);
    if (validationResult !== true) {
      setFormError(validationResult);
      return;
    }
  
    // Prepare payload and document data
    const payload = buildPayload();
    const documentFormData = buildDocumentFormData();
  
    // Exit if no changes in payload
    if (!Object.keys(payload).length && !documentFormData) {
      console.log("No changes detected.");
      return;
    }
  
    setIsLoading(true);
  
    try {
      // Handle guest creation or update
      if (Object.keys(payload).length > 0) {
        const response = id ? await updateGuest({ payload, id }) : await createGuest(payload);
        handleApiResponse(response, "/guest");
      }
  
      // Handle document upload if there are changes
      if (documentFormData) {
        const docResponse = await uploadFiles({ payload: documentFormData, id });
        handleApiResponse(docResponse,"/guest");
      }
    } catch (error) {
      console.error("Submission error:", error);
      toast.error("An unexpected error occurred.");
    } finally {
      setIsLoading(false);
    }
  };
  
  // Helper to build payload with only changed fields
  const buildPayload = () => {
    return Object.keys(form).reduce((acc, field) => {
      if (form[field] !== initialForm[field]) {
        acc[field] = form[field];
      }
      return acc;
    }, {});
  };
  
  // Helper to build FormData for document fields if changed
  const buildDocumentFormData = () => {
    const formData = new FormData();
    ['document_number', 'document_type'].forEach(field => {
      if (documentDetails[field] !== initialDocument[field]) {
        formData.append(field, documentDetails[field]);
      }
    });
  
    return formData.has("document_number") || formData.has("document_type") ? formData : null;
  };
  
  // Generalized API response handler
  const handleApiResponse = (response, redirectPath = null) => {
    if (response?.status === 200 || response?.status === 201) {
      toast.success(response.message);
      if (redirectPath) navigate(redirectPath);
    } else {
      console.error("API Error:", response?.error);
      toast.error(response?.error || "Failed to save data.");
    }
  };
  

  const handleKey = (e, nextInputRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextInputRef) {
        nextInputRef.current.focus();
      } else {
        handleSubmit(e);
      }
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  return (
    <div>
      <Heading title={"Add Guest"} />
      <div className="_addContainer bg-[#FBF9FB]">
        <div className="_midCont_body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-10">
                <div className="_crtDisp_listBx">
                  {/* <div className="">
                    <h4 className="_disPatch_heads">Event Details</h4>
                    <div className="_fleX_inds">
                      <div className="_divForth">
                        <div className="singleDiv">
                          <CommonInput
                            type="text"
                            label="Name"
                            placeholder="Enter Name"
                            name="name"
                            value={form?.name}
                            onChange={({ target: { name, value } }) => {
                              handleOnChange(name, value);
                            }}
                            focus={!!formError?.name}
                            error={formError.name}
                            ref={nameRef}
                            onKeyDown={(e) => handleKey(e, emailRef)}
                            disabled={eventView}
                          />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="">
                    <h4 className="_disPatch_heads">Guest Details</h4>
                    <div className="_fleX_inds">
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          label="Name"
                          placeholder="Enter Name"
                          name="name"
                          value={form?.name}
                          onChange={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                          focus={!!formError?.name}
                          error={formError.name}
                          ref={nameRef}
                          onKeyDown={(e) => handleKey(e, emailRef)}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          label="Email"
                          placeholder="Enter Email Address"
                          name="email"
                          value={form?.email}
                          onChange={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                          focus={!!formError?.email}
                          error={formError.email}
                          ref={emailRef}
                          onKeyDown={(e) => handleKey(e, mobileRef)}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          label="Mobile"
                          placeholder="Enter Mobile Number"
                          name="mobile_number"
                          value={form?.mobile_number}
                          onChange={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                          focus={!!formError?.mobile_number}
                          error={formError.mobile_number}
                          ref={mobileRef}
                          onKeyDown={(e) => handleKey(e, userRef)}
                          disabled={eventView}
                          onKeyPress={handleKeyDown}
                          maxLength={10}
                        />
                      </div>
                      <div className="_divForth"></div>
                      <div className="_divForth">
                        <CustomSelect
                          label="User Type"
                          name="user_type"
                          placeholder="Select User Type"
                          onSelect={(data) => {
                            handleOnChange("user_type", data.value);
                          }}
                          value={userTypeList?.find(
                            (i) => i.value == form?.user_type
                          )}
                          options={userTypeList}
                          focus={!!formError?.user_type}
                          error={formError.user_type}
                          ref={userRef}
                          onEnterSelect={() => genderRef.current.focus()}
                        />
                      </div>

                      <div className="_divForth">
                        <CustomSelect
                          label="Gender"
                          name="gender"
                          placeholder="Select Gender "
                          onSelect={(data) => {
                            handleOnChange("gender", data.value);
                          }}
                          value={genderList?.find(
                            (i) => i.value == form?.gender
                          )}
                          options={genderList}
                          focus={!!formError?.gender}
                          error={formError.gender}
                          ref={genderRef}
                          enableSearch={true}
                          onEnterSelect={() => stateRef.current.focus()}
                        />
                      </div>
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          label="State"
                          placeholder="Enter State"
                          name="state"
                          value={form?.state}
                          onChange={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                          focus={!!formError?.state}
                          error={formError.state}
                          ref={stateRef}
                          onKeyDown={(e) => handleKey(e, cityRef)}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divForth"></div>
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          label="City"
                          placeholder="Enter City"
                          name="city"
                          value={form?.city}
                          onChange={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                          focus={!!formError?.city}
                          error={formError.city}
                          ref={cityRef}
                          onKeyDown={(e) => handleKey(e, addressRef)}
                          disabled={eventView}
                        />
                      </div>

                      <div className="_divFiv">
                        <CommonInput
                          type="text"
                          label="Address"
                          placeholder="Enter Address"
                          name="address"
                          value={form?.address}
                          onChange={({ target: { name, value } }) => {
                            handleOnChange(name, value);
                          }}
                          focus={!!formError?.address}
                          error={formError.address}
                          ref={addressRef}
                          onKeyDown={(e) => handleKey(e, null)}
                          disabled={eventView}
                        />
                      </div>
                    </div>
                  </div>
                  {
                    documentDetails?.document_file && <div className="">
                      <h4 className="_disPatch_heads">Uploaded Image</h4>
                      <div className="_fleX_inds">
                        <div className="_divForth">
                        <DocumentImages documentDetails={documentDetails} />
                        </div>
                        <div className="_divForth">
                          <CustomSelect
                            placeholder="Select Document Type"
                            label="Document Type"
                            options={documentypeList}
                            onSelect={(data) => {
                              setDocumentDetails({
                                ...documentDetails,
                                document_type: data?.value,
                              });
                            }}
                            focus={
                              !!(
                                typeof formError === "object" &&
                                formError?.document_type
                              )
                            }
                            error={
                              !!(typeof formError === "object")
                                ? formError?.document_type
                                : ""
                            }
                            value={documentypeList?.find(
                              (i) => i?.value === documentDetails?.document_type
                            )}
                            ref={typeRef}
                            enableSearch={true}
                            disabled={eventView}
                            // onEnterSelect={() => Ref.current.focus()}
                          />
                        </div>
                        <div className="_divForth">
                          <CommonInput
                            type="text"
                            placeholder="Enter Number"
                            label="Document Number"
                            name="document_number"
                            value={documentDetails?.document_number}
                            focus={
                              !!(
                                typeof formError === "object" &&
                                formError?.document_number
                              )
                            }
                            error={
                              !!(typeof formError === "object")
                                ? formError?.document_number
                                : ""
                            }
                            onChange={({ target: { name, value } }) =>
                              setDocumentDetails({
                                ...documentDetails,
                                document_number: value,
                              })
                            }
                            ref={numberRef}
                            disabled={eventView}
                            // onKeyDown={(e) => handleKey(e, null)}
                          />
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomFix">
        <button
          className="_addComm_btn"
          type="button"
          onClick={() => handleSubmit()}
          disabled={isLoading || eventView}
        >
          {isLoading ? <BasicExample /> : "Add Guest"}
        </button>
      </div>
    </div>
  );
};

export default CreateGuest;







