


export function handleKeyDown(event) {
  
    function isValidNumber(val) {
      // Allow empty string or check if the value is a valid number (including decimals)
      if (val === "") return true;
      return !!(!isNaN(val) && !isNaN(parseFloat(val)));
    }
    
    function isSpace(str) {
      return str === " ";
    }
    
    function isWhitelistedKey(key) {
      const whitelistedActions = [
        "Backspace",
        "ArrowLeft",
        "ArrowRight",
        "ArrowUp",
        "ArrowDown",
        "Tab",
        "Delete",
        "." // Allow decimal separator
      ];
      return whitelistedActions.includes(key);
    }

    // Allow whitelisted keys
    if (isWhitelistedKey(event.key)) {
      // let it through
    } else if (isSpace(event.key) || !isValidNumber(event.target.value + event.key)) {
      // Prevent default action for space or invalid numbers
      event.preventDefault();
    }
}

