
import { login } from "features";
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../assets/css/onboard.css";
import StorageService from "utils/StorageServices/StorageServices";
import { CommonInput } from "components";
import { errorSchema, utils } from "helper";
import toast from "react-hot-toast";
import { Images } from "constants";
import { BasicExample } from "components/common/CommonModal/CommonModal";

const Login = () => {
  
  const emailRef = useRef()
  const passRef = useRef()
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((state) => state.auth);
  const [formError, setFormError] = useState("");
  const [form, setForm] = useState({
    email: "",
    password:""
  });

  useEffect(() => {
    if (emailRef.current) {
      emailRef.current.focus(); 
    }
  }, []);

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.loginSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationResult = await utils?.checkFormError(
      form,
      errorSchema.loginSchema
    );
    if (validationResult === true) {
      try {
        const res = await dispatch(login(form));
        if (res.payload?.status === 200) {
          StorageService.setToken(res?.payload?.results?.access)
          navigate("/dashboard");
        }else{
          toast.error(res.payload?.message)
        }
      } catch (error) {throw error}
    }else{
      setFormError(validationResult)
    }
      
    
  };


  const handleKey = (e,nextInputRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextInputRef) {
        nextInputRef.current.focus(); 
      } else {
        handleSubmit(e);
      }
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
      }
  };

  return (
    <div className="_midOb_bx">
      <div className="_midOd_head">
          {/* <h3>Welcome To Dharti Dhora Ri </h3> */}
      
          <img className="_logoOn_board " src={Images.LOGO} alt="" />
        </div>
      <div className="_innrMid_bx">
        
          <div className="_subFrm_fx">
          <div className="singleDiv">
              <CommonInput
                type="email"
                label="Email"
                placeholder="Enter Email"
                name="email"
                value={form?.email}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name,value);
                }}
                focus={!!formError?.email}
                error={formError.email}
                ref={emailRef}
                onKeyDown={(e) => handleKey(e, passRef)}
                
              />
            </div>
            <div className="singleDiv">
              <CommonInput
                type="password"
                label="Password"
                placeholder="Enter Password"
                name="password"
                value={form?.password}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name,value);
                }}
                focus={!!formError?.password}
                error={formError.password}
                ref={passRef}
                onKeyDown={(e) => handleKey(e, null)}
                
              />
            </div>

            <button
              type="submit"
              disabled={loading}
              className="anime-btn _submitOn_board"
              onClick={(e)=>handleSubmit(e)}
            >
              {loading ? <BasicExample/> : "Login"}{" "}
            </button>
          </div>
        
      </div>

      {/* <div className="_midBx_foot">
        <p>
          By clicking on Verify you agree to our <a href="">Terms</a> and{" "}
          <a href="">Privacy Policy</a>
        </p>
        <p>© 2024 . All rights reserved.</p>
      </div> */}
    </div>
  );
};

export default Login;
