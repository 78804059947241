
import React, { useState } from "react";
import LoadingBar from "react-top-loading-bar";
import Tab from 'components/common/Tab';
import { constant } from 'constants/constant';
import MainDashboard from "./MainDashboard";


const Dashboard = () => {
  const [activeTab, setActiveTab] = useState(1);
  const loadingBarRef = React.useRef(null)
  // const simulateLoading = () => {
  //   loadingBarRef.current.continuousStart();
  //   setTimeout(() => {
  //     loadingBarRef.current.complete();
  //   }, 1000); // Adjust this timeout as needed to simulate loading time
  // };

  // // Simulate loading when the component mounts
  // React.useEffect(() => {
  //   simulateLoading();
  // }, []);

  const tabProps = {
    tabList: constant.dashboardTabs,
    callBackTab: (e) => setActiveTab(e),
    activeTab: activeTab
  };

  return (
    <>
      {/* <Heading title={"Dashboard"} /> */}
      {/* <div>Dashboard</div> */}
      {/* <AddAccount /> */}
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />

      <div className='addPr_flBx'>
        <Tab {...tabProps} />
        {activeTab === 1 && (
          <MainDashboard/>
        )}
      </div >


    </>
  );
};

export default Dashboard;
