import { lazy } from "react";

//lazy-loaded Layouts
const DashboardLayout = lazy(() =>
  import("layouts").then((module) => ({ default: module.DashboardLayout }))
);
const AuthLayout = lazy(() =>
  import("layouts").then((module) => ({ default: module.AuthLayout }))
);
const ProfileLayout = lazy(() =>
  import("layouts").then((module) => ({ default: module.ProfileLayout }))
);

// lazy-loaded Pages
const lazyLoad = (moduleName) =>
  lazy(() =>
    import("pages").then((module) => ({ default: module[moduleName] }))
  );

const Login = lazyLoad("Login");
const Dashboard = lazyLoad("Dashboard");
const AddressBook = lazyLoad("AddressBook");
const Guest = lazyLoad("Guest");
const CreateGuest = lazyLoad("CreateGuest")
const Report = lazyLoad("Report");
const Event = lazyLoad("Event");
const Floor = lazyLoad("Floor");
const Room = lazyLoad("Room");
const RoomBook = lazyLoad("RoomBook");
const CreateRoomBooking = lazyLoad("CreateRoomBooking");
const KeyRequest = lazyLoad("KeyRequest");
const CreateKeyRequest = lazyLoad("CreateKeyRequest");
// creating route objects
const createRoute = (path, element, isPrivate = false) => ({
  path,
  element,
  isPrivate,
});

export const routeConfigs = [
  {
    layout: AuthLayout,
    children: [
      createRoute("/", Login, false),
      createRoute("other", Login, false),
  
    ],
  },
  {
    layout: DashboardLayout,
    children: [
      createRoute("dashboard", Dashboard, true),
      createRoute("address_book", AddressBook, true),
      createRoute("guest", Guest, true),
      createRoute("guest/create", CreateGuest, true),
      createRoute("guest/edit/:id", CreateGuest, true),
      createRoute("guest/view/:id/:eventView", CreateGuest, true),
      createRoute("hotal_report", Report, true),
      createRoute("master/event", Event, true),
      createRoute("master/floor", Floor, true),
      createRoute("master/room", Room, true),
      createRoute("booking/room", RoomBook, true),
      createRoute("booking/room/create", CreateRoomBooking, true),
      createRoute("booking/room/edit/:id", CreateRoomBooking, true),
      createRoute("booking/room/view/:id/:eventView", CreateRoomBooking, true),
      createRoute("keyRequest", KeyRequest, true),
      createRoute("keyRequest/create", CreateKeyRequest, true),
      createRoute("keyRequest/edit/:id", CreateKeyRequest, true),
      createRoute("keyRequest/view/:id/:eventView", CreateKeyRequest, true),
    ],
  },
  // {
  //   layout: ProfileLayout,
  //   children: [
  //     createRoute('register', Register, true),
  //     createRoute('profile', SelectCompany, true),
  //     createRoute('custom-table', Custom, true),
  //   ],
  // },
];
