
import { UserContext } from "context";
import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { AiOutlineEye } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit } from "react-icons/fi";
import { deleteUserData } from "_services";
import { ButtonHeading, CommonModal, CustomPagination } from "components";
import BootstrapTable from "components/BootstrapTable";


const Report = () => {
  const { masterData } = useContext(UserContext);
  const navigate = useNavigate();
  const loadingBarRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [addressBooklist, setAddressBookList] = useState([]);
  const [zoneList,setZoneList] = useState([])
  const [addressId,setAddressId] = useState({
    id:"",
    title:"address"
  })

  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: "",
    status: 1,
  });

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);

  const columns = [
    { header: "", accessor: "serial" },
    { header: "Type", accessor: "category" },
    { header: "Code", accessor: "code" },
    { header: "Name", accessor: "name" },
    { header: "City", accessor: "city" },
    { header: "Zone", accessor: "zoneName" },
    { header: "Contact", accessor: "contact_no" },
    { header: "Status", accessor: "status" },
  ];

  // useEffect(() => {
  //   getAddressBookList();
  //   getZoneList()
  // }, [filter, showOffcanvas]);

  // const getZoneList = async () => {

  //   let object = { company_id: StorageService.getCompanyId() };
  //   const res = await getZoneDropdownListData(object)
  //   console.log(res)
  //   if (res?.responseCode === 200) {
  //     setZoneList(res.data?.zone_list);
    
  //   }
  // };

  // const getAddressBookList = async () => {
  //   try {
  //     if (loadingBarRef.current) {
  //       loadingBarRef.current.continuousStart(); // Start the loading bar
  //     }
  //     let object = {
  //       ...filter,
  //       company_id: StorageService.getCompanyId(),
  //     };
  //     const res = await getAddressBookListData(object);
  //     if (res?.responseCode === 200) {
  //       setAddressBookList(res.data?.addressbook_list);
  //       setTotalItems(res?.data?.total_record);
  //     }
  //   } catch (error) {
  //     toast.error(error);
  //   } finally {
  //     if (loadingBarRef.current) {
  //       loadingBarRef.current.complete();
  //     }
  //   }
  // };

  const editHandler = (data) => {};

  const deleteHandler = (item) => {
    // setIsDelete(item)
  };

  const statusChangeHnadle = async (data, index) => {
   
  };

  const handleStatusEvent = async (data, index) => {
    statusChangeHnadle(data, index);
    const list = [...addressBooklist];
    list[index].active = data?.active === 1 ? 0 : 1;
    setAddressBookList(list);
  };

  const callBackAddressData = ()=>{
    setAddressId({id:"",title:"address"})
  }


  const rowListData = (data) => {

    return data?.map((item, i) => ({
      ...item,
      serial: (
        <React.Fragment>
          <div className="signDropdown">
            <button className="dropbtn11">
              <BsThreeDotsVertical />
            </button>
            <div className="dropdownContent">
              <Link
                // to={`/account/supplier/details/${item?._id}`}
                className="_avPointer"
                style={{ textDecoration: "none" }}
              >
                <span className="_samAnchor">
                  <span className="viewIcon_avt">
                    <AiOutlineEye />
                  </span>
                  &nbsp; View
                </span>
              </Link>
              <Link
                onClick={()=>{
                  setAddressId({...addressId,
                    id:item?._id
                  })
                  setShowOffcanvas(true)
                }}
                className="_avPointer"
                style={{ textDecoration: "none" }}
              >
                <span className="_samAnchor">
                  <span className="viewIcon_avt">
                    <FiEdit />
                  </span>
                  &nbsp; Edit
                </span>
              </Link>
              {/* <div
                    
                    className='_avPointer'
                  >
                    <span className='_samAnchor'>
                      <span className='editIcon_avt'>
                        <FiEdit />
                      </span>
                      Edit
                    </span>
                  </div> */}

              <div onClick={(e) => deleteHandler(item)} className="_avPointer">
                <span className="_samAnchor">
                  <span className="deleteIcon_avt">
                    
                  </span>
                  Delete
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),

     
      zoneName: zoneList?.find((i) => i?._id === item?.zone_id)?.name || "",
      supplier_name: (
        <Link style={{ textDecoration: "none" }}>
          {item?.company_user_name ? item?.company_user_name : "-"}
        </Link>
      ),
      contact_number: (
        <React.Fragment>
          {item?.user_id?.mobile_no ? item?.user_id?.mobile_no : "-"}
        </React.Fragment>
      ),
      category: (
        <React.Fragment>
          {
            masterData?.addressbook_category_list.find(
              (fx) => fx?.id == item?.category
            )?.name
          }
        </React.Fragment>
      ),
      party_group: (
        <React.Fragment>
          {item?.consignor_group_id?.name
            ? item?.consignor_group_id?.name
            : "-"}
        </React.Fragment>
      ),
      city: (
        <React.Fragment>
          {item?.city?.label ? item?.city?.label : "-"}
        </React.Fragment>
      ),
      status: (
        <div
          className="_anvSwitcherNew form-check form-switch"
          style={{ width: "125px" }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={item?.active}
            onChange={() => handleStatusEvent(item, i)}
          />
          <span
            className={
              item?.active
                ? "active_label_masters_status"
                : "disabled_label_masters_status"
            }
          >
            {item?.active ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      balance: item?.balance ? (
        <Link
          to={`/account/supplier/details/${item?._id}?tab=4`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color:
                item.balance < 0
                  ? "#dc3545"
                  : item.balance > 0
                  ? "#28a745"
                  : "#1450ec",
            }}
          >
            <span>
            
            </span>
            <span>{item.balance}</span>
          </div>
        </Link>
      ) : (
        <Link
          to={`/account/supplier/details/${item?._id}?tab=4`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color: "#1450ec",
            }}
          >
            <span>
            
            </span>
            <span>0</span>
          </div>
        </Link>
      ),
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };


  const submitDeleteSupplier = async () => {
    setLoading(true);
    try {
      loadingBarRef.current?.continuousStart();
      let object = {
        company_id: isDelete?.company_id?._id,
        param_user_id: isDelete?.user_id?._id,
      };

      const res = await deleteUserData(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);

        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success("Error !! ");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteSupplier,
    isLoadding: loading,
  };

  const [city, setCity] = useState("");

  const handleCityChange = (value) => {
    setCity(value);
    console.log("Selected city:", value);
  };

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"Report"}
        onClick={() => setShowOffcanvas(true)}
        buttonTitle={"Add Report"}
        showSearchFilter={{
          search: true,
          filterOption: true,
          filterCount: true,
        }}
        onClickFilterModal={(e) => setShowFilterModal(false)}
        filter={filter}
        setFilter={setFilter}
      />
      
      <div className="_tabWith_outTab">
        <BootstrapTable
          columns={columns}
          data={rowListData(addressBooklist?.length ? addressBooklist : [])}
          isLoading={loading}
        />

       { addressBooklist?.length && <div className="paginationFt_avt "> <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        /></div>}
      </div>

      {/* <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showOffcanvas ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showOffcanvas ? "visible" : "hidden" }}
      ></div>

      <div className={showOffcanvas ? "offBack_drop" : ""}></div> */}

      {/* <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showFilterModal ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? "visible" : "hidden" }}
      >
        {showFilterModal && (
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        )}
      </div> */}
      <div className={showFilterModal ? "offBack_drop" : ""}></div>

      {isDelete?.company_id && (
        <CommonModal {...propsObject}>
          <p className="p-3"> Do you really want to delete {isDelete?.name} </p>
        </CommonModal>
      )}

    
    </div>
  );
};

export default Report;
