// src/features/auth/authSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { createEvent } from './masterActions';

const initialState = {
    data: null,
    userData: null,
    loading: false,
    error: null,
};

const masterSlice = createSlice({
    name: 'master',
    initialState,
    reducers: {
        resetError(state) {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(createEvent.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(createEvent.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })


            // .addCase(OtpVerify.pending, (state) => {
            //     state.loading = true;
            //     state.error = null;
            // })
            // .addCase(OtpVerify.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.userData = action.payload;
            // })
            // .addCase(OtpVerify.rejected, (state, action) => {
            //     state.loading = false;
            //     state.error = action.payload;
            // })

            // .addCase(account.pending, (state) => {
            //     state.loading = true;
            //     state.error = null;
            // })
            // .addCase(account.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.data = action.payload;
            // })
            // .addCase(account.rejected, (state, action) => {
            //     state.loading = false;
            //     state.error = action.payload;
            // })


            // .addCase(addCompany.pending, (state) => {
            //     state.loading = true;
            //     state.error = null;
            // })
            // .addCase(addCompany.fulfilled, (state, action) => {
            //     state.loading = false;
            //     state.data = action.payload;
            // })
            // .addCase(addCompany.rejected, (state, action) => {
            //     state.loading = false;
            //     state.error = action.payload;
            // })

            // .addCase(logout.fulfilled, (state) => {
            //     state.data = null;
            // });
    },
});

export const { resetError } = masterSlice.actions;

export default masterSlice.reducer;
