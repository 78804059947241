import {
  deleteMasterRoom,
  deleteRoomBook,
  getRoom,
  getRoomBooking,
  updateRoomBooking,
} from "_services";
import {
  ButtonHeading,
  CommonModal,
  CommonPagination,
  CustomPagination,
} from "components";
import BootstrapTable from "components/BootstrapTable";
import { AddRoomBooking, AddRoomModal, UploadDocument } from "modals";
import MastersFilterModal from "modals/MastersFilterModal";
import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineEye } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegTrashCan } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { UserContext } from "context";
import { GrDocumentUpload } from "react-icons/gr";
import { utils } from "helper";

const RoomBook = () => {
  const { masterData } = useContext(UserContext);
  const navigate = useNavigate();
  const loadingBarRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [allRoomlist, setAllRoomList] = useState([]);
  const [eventView, setEventView] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [roomId, setRoomId] = useState(false);
  const [filter, setFilter] = useState({
    event_id:"",
    guest_search:"",
    date_from:"",
    date_to:"",
    room_number:"",
    floor_name:""
  });

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const columns = [
    { header: "Floor Name", accessor: "floor_name" },
    { header: "Room Number", accessor: "room_number" },
    { header: "Event Name", accessor: "event_name" },
    { header: "Guest Name", accessor: "GuestName" },
    { header: "Mobile Number", accessor: "contact_number" },
    { header: "Check-In", accessor: "checkIn" },
    { header: "Check-Out", accessor: "checkOut" },
    { header: "", accessor: "action" },
    { header: "", accessor: "buttonAction" },

    // { header: "Status", accessor: "status" },
  ];

  useEffect(() => {
    getRoomList();
  }, [filter]);

  const getRoomList = async (url = null) => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart();
      }

      const filterParams = new URLSearchParams();

      // Add each filter parameter if it has a value
      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          filterParams.append(key, filter[key]);
        }
      });

    const urlmodify = `${url}?${filterParams.toString()}`;

      const res = await getRoomBooking(url,filterParams.toString());

      if (res?.status === 200) {
        setAllRoomList(res?.results);
        setNextPageUrl(res?.next);
        setPrevPageUrl(res?.previous);
        setTotalItems(res?.count);
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }
    }
  };

  const deleteHandler = (item) => {
    setIsDelete(item);
  };

  const rowListData = (data) => {
    return data?.map((item, i) => ({
      ...item,
      serial: (
        <React.Fragment>
          <div className="signDropdown">
            <button className="dropbtn11">
              <BsThreeDotsVertical />
            </button>
            <div className="dropdownContent">
              <Link
                onClick={() => {
                  navigate(`/booking/room/view/${item?.id}/${true}`);
                }}
                className="_avPointer"
                style={{ textDecoration: "none" }}
              >
                <span className="_samAnchor">
                  <span className="viewIcon_avt">
                    <AiOutlineEye />
                  </span>
                  &nbsp; View
                </span>
              </Link>
              <Link
                onClick={() => {
                  navigate(`/booking/room/edit/${item?.id}`);
                  // setAddressId(item?.id)
                  // setShowOffcanvas(true)
                }}
                className="_avPointer"
                style={{ textDecoration: "none" }}
              >
                <span className="_samAnchor">
                  <span className="viewIcon_avt">
                    <FiEdit />
                  </span>
                  &nbsp; Edit
                </span>
              </Link>
              {/* <div
                    
                    className='_avPointer'
                  >
                    <span className='_samAnchor'>
                      <span className='editIcon_avt'>
                        <FiEdit />
                      </span>
                      Edit
                    </span>
                  </div> */}

              <div onClick={(e) => deleteHandler(item)} className="_avPointer">
                <span className="_samAnchor">
                  <span className="deleteIcon_avt">
                    <FaRegTrashCan color="red" />
                  </span>
                  Delete
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),
      checkIn: (
        <>
         {` ${utils.formatDateMonth(item?.check_in)} | ${utils.formatTime(item?.check_in)}`}
        </>
      ),
      checkOut: (
        <>
         {` ${utils.formatDateMonth(item?.check_out)} | ${utils.formatTime(item?.check_out)}`}
        </>
      ),
      // zoneName: zoneList?.find((i) => i?._id === item?.zone_id)?.name || "",
      supplier_name: (
        <Link style={{ textDecoration: "none" }}>
          {item?.company_user_name ? item?.company_user_name : "-"}
        </Link>
      ),
      contact_number: (
        <React.Fragment>
          {item?.guest?.mobile_number ? item?.guest?.mobile_number : "-"}
        </React.Fragment>
      ),
      category: (
        <React.Fragment>
          {
            masterData?.addressbook_category_list.find(
              (fx) => fx?.id == item?.category
            )?.name
          }
        </React.Fragment>
      ),
      GuestName: (
        <React.Fragment>
          {item?.guest?.name ? item?.guest?.name : "-"}
        </React.Fragment>
      ),
      GuestEmail: (
        <React.Fragment>
          {item?.guest?.email ? item?.guest?.email : "-"}
        </React.Fragment>
      ),
      city: (
        <React.Fragment>
          {item?.city?.label ? item?.city?.label : "-"}
        </React.Fragment>
      ),
      status: (
        <div
          className="_anvSwitcherNew form-check form-switch"
          style={{ width: "125px" }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={item?.active}
            // onChange={() => handleStatusEvent(item, i)}
          />
          <span
            className={
              item?.active
                ? "active_label_masters_status"
                : "disabled_label_masters_status"
            }
          >
            {item?.active ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      action: (
        <div
          className="_anvSwitcherNew form-check form-switch"
          style={{ width: "100px" }}
        >
          <span
            className="viewIcon_avt"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/booking/room/view/${item?.id}/${true}`);
            }}
          >
            <AiOutlineEye color="green" />
          </span>
          <span
            className="viewIcon_avt"
            style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/booking/room/edit/${item?.id}`);
            }}
          >
            <FiEdit color="blue" />
          </span>
          <span
            className="deleteIcon_avt"
            style={{ cursor: "pointer" }}
            onClick={(e) => deleteHandler(item)}
          >
            <FaRegTrashCan color="red" />
          </span>
          {!item?.guest?.document_file && <span
            className="viewIcon_avt"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setAddressId(item?.guest?.id);
              setShowOffcanvas(true);
            }}
          >
            <GrDocumentUpload color="#07accf" />
          </span>}
        </div>
      ),
      buttonAction: (
        <React.Fragment>
          <div
            className="_anvSwitcherNew form-check form-switch"
            style={{ width: "150px" }}
          >
           { !item?.check_out && <div className="_listbtn">
              <button
                className="_addComm_btn _builtyPrintBtn"
                onClick={() => setRoomId(item)}
              >
                Check-out
              </button>
            </div>}
          </div>
        </React.Fragment>
      ),
      balance: item?.balance ? (
        <Link
          to={`/account/supplier/details/${item?._id}?tab=4`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color:
                item.balance < 0
                  ? "#dc3545"
                  : item.balance > 0
                  ? "#28a745"
                  : "#1450ec",
            }}
          >
            <span></span>
            <span>{item.balance}</span>
          </div>
        </Link>
      ) : (
        <Link
          to={`/account/supplier/details/${item?._id}?tab=4`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color: "#1450ec",
            }}
          >
            <span></span>
            <span>0</span>
          </div>
        </Link>
      ),
    }));
  };

  const callBackAddressData = () => {
    setEventView(false);
    getRoomList();
    setAddressId("");
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };

  const convertDate = () => {
    const now = new Date();
    const localTime = now.getTime();
    const IST_OFFSET = 5.5 * 60 * 60 * 1000;
    const istTime = new Date(localTime + IST_OFFSET);
    const formattedDateTime = istTime.toISOString();
    return formattedDateTime;
  };

  const checkoutHandle = async () => {
    const payload = {
      check_out: convertDate(),
    };
    try {
      const res = await updateRoomBooking({
        payload,
        id: roomId?.id,
      });
      if (res.status === 200) {
        setLoading(false);
        setRoomId(false);
        getRoomList();
        toast.success(res.message);
      } else {
        toast.error(res.error);
      }
    } catch (error) {}
  };

  const submitDeleteSupplier = async () => {
    setLoading(true);
    try {
      loadingBarRef.current?.continuousStart();
      let object = {
        id: isDelete?.id,
      };

      const res = await deleteRoomBook(object);
      if (res?.status == 200) {
        toast.success(`${res?.message}`);
        getRoomList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.error(res?.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleNextPage = () => {
    if (nextPageUrl) {
      getRoomList(nextPageUrl);
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      getRoomList(prevPageUrl);
    }
  };

  const callBackNext = () => {
    handleNextPage();
  };

  const callBackPre = () => {
    handlePrevPage();
  };

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteSupplier,
    isLoadding: loading,
  };

  let checkoutProps = {
    isOpen: roomId,
    closeModal: setRoomId,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: checkoutHandle,
    isLoadding: loading,
  };

  let eventProps = {
    isOpen: showOffcanvas,
    closeModal: (e) => {
      setAddressId("");
      setShowOffcanvas(false);
    },
    modalMainClass: "_dashCommon_Modal _smModal",
    loading: loading,
    modalTitle: "Upload Document",

    submitTitle: "Upload Document",
    callBackAddressData: callBackAddressData,
    addressId: addressId,
    eventView: eventView,
  };

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"Room Booking"}
        onClick={() => navigate("/booking/room/create")}
        buttonTitle={"Add Room Booking"}
        showSearchFilter={{
          search: false,
          filterOption: true,
          filterCount: true,
        }}
        onClickFilterModal={(e) => setShowFilterModal(true)}
        filter={filter}
        setFilter={setFilter}
      />

      <div className="_tabWith_outTab">
        <BootstrapTable
          columns={columns}
          data={rowListData(allRoomlist?.length ? allRoomlist : [])}
          isLoading={loading}
        />

        {allRoomlist?.length > 10 && (
          <div className="paginationFt_avt ">
            {" "}
            <CommonPagination
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              handleNextPage={callBackNext}
              handlePrevPage={callBackPre}
            />
          </div>
        )}
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showFilterModal ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? "visible" : "hidden" }}
      >
        {showFilterModal && (
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
            isShow={{event_id:true,guest_search:true,date_from:true,date_to:true,room_number:true,floor_name:true}}
            name="booking"
          />
        )}
      </div>
      <div className={showFilterModal ? "offBack_drop" : ""}></div>

      {isDelete?.id && (
        <CommonModal {...propsObject}>
          <p className="p-3">
            {" "}
            Do you really want to delete {isDelete?.room_number}{" "}
          </p>
        </CommonModal>
      )}

      {roomId?.id && (
        <CommonModal {...checkoutProps}>
          <p className="p-3">
            {" "}
            Do you really want to Check-Out from room : {
              roomId?.room_number
            }{" "}
          </p>
        </CommonModal>
      )}

      {showOffcanvas && <UploadDocument {...eventProps} />}
    </div>
  );
};

export default RoomBook;
