import _ from "lodash";

const hostname = window.location.hostname;

const s3_url = ""
const s3_prefix = ""

const regex =
  /(192)\.(168)(\.(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])){2}$/gm;

const live = {
  api_baseurl: "https://cms-api.dhartidhorari.com/api/",
  baseurl: "https://cms-api.dhartidhorari.com/api/",
  socketurl: "",
  s3_url: "",
  s3_prefix: "",
  environment: 'live',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

const dev = {
  api_baseurl: "https://cms-api.dhartidhorari.com/api/",
  baseurl: "https://cms-api.dhartidhorari.com/api/",
  socketurl: "",
  s3_url,
  s3_prefix,
  environment: 'dev',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
    "Authorization": "Virendra",
 
  },
};

const stag = {
  api_baseurl: "https://cms-api.dhartidhorari.com/api/",
  baseurl: "https://cms-api.dhartidhorari.com/api/",
  socketurl: "",
  s3_url: "",
  s3_prefix: "",
  environment: 'live',
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Accept-Language": "en",
  },
};

      const AppConfig =dev
export default AppConfig;