import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';

function MobileCamera({ onCapture, onUpload, uploadUrl }) {
  const webcamRef = useRef(null);
  const [imageFile, setImageFile] = useState(null);
  const [isCameraOpen, setIsCameraOpen] = useState(false);

  // Detect if device is mobile
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  // Open the camera for desktop (Webcam component)
  const handleOpenCamera = () => setIsCameraOpen(true);

  // Capture image using the Webcam component (for desktop)
  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    fetch(imageSrc)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], "captured_image.jpg", { type: "image/jpeg" });
        setImageFile(file);
        setIsCameraOpen(false);

        if (onCapture) {
          onCapture(file);
        }
      })
      .catch(error => console.error("Error capturing image:", error));
  };

  // Handle capture from mobile device input
  const handleMobileCapture = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImageFile(file);
      if (onCapture) {
        onCapture(file);
      }
    }
  };

  // Handle the upload process
  const handleUpload = () => {
    if (!imageFile || !uploadUrl) {
      console.warn("Image or upload URL is missing.");
      return;
    }

    const formData = new FormData();
    formData.append("file", imageFile);

    fetch(uploadUrl, {
      method: "POST",
      body: formData,
    })
      .then(response => response.json())
      .then(data => {
        if (onUpload) {
          onUpload(data);
        }
      })
      .catch(error => console.error("Error uploading image:", error));
  };

  return (
    <div>
      {isMobile ? (
        <>
          <input
            type="file"
            accept="image/*"
            capture="environment" // Directly opens the mobile camera in most devices
            onChange={handleMobileCapture}
            style={{ display: 'none' }}
            id="mobileFileInput"
          />
          <button onClick={() => document.getElementById('mobileFileInput').click()} className="_addComm_btn _builtyPrintBtn">
            Open Mobile Camera
          </button>
        </>
      ) : (
        <button onClick={handleOpenCamera} className="_addComm_btn _builtyPrintBtn">Open Camera</button>
      )}

      {isCameraOpen && !isMobile && (
        <div>
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{ facingMode: "environment" }}
            style={{ width: "100%", maxHeight: "500px" }}
          />
          <button onClick={handleCapture} className="_addComm_btn _builtyPrintBtn">Capture Image</button>
        </div>
      )}

      {imageFile && (
        <div>
          <img src={URL.createObjectURL(imageFile)} alt="Preview" style={{ width: "100%", maxHeight: "250px" }} />
          
        </div>
      )}
    </div>
  );
}

export default MobileCamera;
