import { createMasterEvent, createMasterRoom, getAllFloor, getSingleMasterRoom, handleKeyDown, updateMasterRoom } from "_services";
import {
  CommonDate,
  CommonDatePicker,
  CommonInput,
  CommonModal,
  CustomSelect,
} from "components";
import { createEvent } from "features/masterService";
import { errorSchema, utils } from "helper";
import React, { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddRoomModal = (props) => {
  const [form, setForm] = useState({
    floor: "",
    room_number: ''
  });
  const [initialForm, setInitialForm] = useState({
    floor: "",
    room_number: ''
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nameRef = useRef()
  const startRef = useRef()
  const endRef = useRef()
  const [formError, setFormError] = useState("");
const [isLoading,setIsLoading] = useState(false)
const [allFloorList,setAllFloorList] = useState([])

useEffect(()=>{
  getFloorList()
},[])

useEffect(() => {
  if (props?.addressId && allFloorList.length) {
    getRoomDetails(props?.addressId);
  }
}, [props?.addressId,allFloorList]);

// useEffect(() => {
//   if (startRef.current) {
//     startRef.current.focus(); 
//   }
// }, []);


const getRoomDetails = async (id) => {
  try {
    const res = await getSingleMasterRoom(id);

    if (res?.status === 200) {
      const eventData = {
        floor: res?.results?.floor,
        room_number: res?.results?.room_number,
      };
      setForm(eventData);
      setInitialForm(eventData); 
      
    }
  } catch (error) {}
};

const getFloorList = async () => {
   
  try {
    
    const res = await getAllFloor({ limit: "all" });
   
    if (res?.status === 200) {
    setAllFloorList(res?.results.map((i)=>{
return {
  label:i?.name,
  value:i?.id
}
    }))
   
  }else{
    toast.error(res.error);
  }
  } catch (error) {
    toast.error(error);
  } finally {
   
  }
};

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.roomSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const handleSubmit = async () => {
    const validationResult = await utils?.checkFormError(
      form,
      errorSchema.roomSchema
    );
    const payload = {}

    Object.keys(form).map((item, index) => {
      if (form[item] !== initialForm[item]) {
        payload[item] = form[item];
      }
    });

    if (validationResult === true) {
        
        try {
          setIsLoading(true)
          if (props?.addressId) {
            const res = await updateMasterRoom({
              payload,
              id: props?.addressId,
            });
  
           
            if (res.status === 200) {
              setIsLoading(false)
              toast.success(res.message);
              props?.closeModal();
              props?.callback();
            }else{
              toast.error(res.error);
            }

          } else {
            const res = await createMasterRoom(payload);
            if (res.status === 201) {
                setIsLoading(false)
                toast.success(res.message);
                props?.closeModal();
                props?.callback();
              }else{
                toast.error(res.error);
              }
            }
            } catch (error) {
              setIsLoading(false)
            }
      }else{
        setFormError(validationResult)
      }
    
  };

  const objectBid = {
    ...props,
    onSubmit: handleSubmit,
    disabled:isLoading || props?.eventView,
    isLoadding:isLoading
  };

  const handleKey = (e, name,nextInputRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextInputRef) {
        nextInputRef.current.focus(); 
      } else {
        handleSubmit(e);
      }
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
      }
  };

  return (
    <>
      <CommonModal {...objectBid}>
        <div className="_plcMod_body" style={{ padding: "20px" }}>
          <div className="flxInr">
            <div className="singleDiv">
             
              <CustomSelect
                   label="Floor"
                placeholder="Select Floor"
                  onSelect={(data) => {
                    handleOnChange("floor", data.value);
                  }}
                  value={
                    allFloorList?.find((i) => i.value == form?.floor)
                  }
                  options={allFloorList}
                  focus={!!formError?.floor}
                  error={formError.floor}
                  ref={startRef}
                  enableSearch={true}
                  onEnterSelect={() => endRef.current.focus()}
                  disabled={props?.eventView}
                />
            </div>
            <div className="singleDiv">
            <CommonInput
               type="text"
                label="Room Number"
                placeholder="Enter Room Number"
                name="room_number"
                value={form?.room_number}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name, value);
                }}
                focus={!!formError?.room_number}
                error={formError.room_number}
                ref={endRef}
                onKeyDown={(e) => handleKey(e, "room_number", null)}
                onKeyPress={handleKeyDown}
                disabled={props?.eventView}
              />
            </div>
            <div className="singleDiv">
              
            </div>
          </div>
        </div>
      </CommonModal>
      <div className="offBack_drop"></div>
    </>
  );
};

export default AddRoomModal;
