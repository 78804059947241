import * as Yup from "yup";

const vehicleNumberPattern = /^[A-Z]{2}[0-9]{2}[A-Z]{1,2}[0-9]{4}$/;

export const errorSchema = {
  createParty: Yup.object().shape({
    name: Yup.string().required("Required *"),
    as_of_date: Yup.string().required("Required *"),
    gst_treatment_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    gst_state: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    consignor_group_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    template_no: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
  }),
  createUnit: Yup.object().shape({
    name: Yup.string().required("Required *"),
    contact_no: Yup.string().min(10, "Required").required("Required *"),
    gst_treatment_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    gst_state: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    consignor_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    zone_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    // gst_treatment_id: Yup.string().required("Required *"),
    // gst_state: Yup.string().required("Required *"),
    // consignor_id: Yup.string().required("Required *"),
    // zone_id: Yup.string().required("Required *"),
  }),

  addProfileModalSchema: Yup.object().shape({
    full_name: Yup.string().required("Required"),
    city: Yup.object().shape({
      name: Yup.string().required("city is required"),
    }),
  }),

  addCompanyModalSchema: Yup.object().shape({
    company: Yup.string().required("Required"),
    entity_type: Yup.string().required("Required"),
    contact_no: Yup.string().required("Required"),
    is_gst: Yup.boolean(),
    gst_no: Yup.string().when("is_gst", {
      is: (test) => test === true, // Validation will apply when the checkbox is true
      then: () =>
        Yup.string()
          .required("GST number is required")
          .matches(
            /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[0-9A-Z]{1}$/,
            "Invalid GST format"
          ),
    }),
    city: Yup.object({
      label: Yup.string().required("city is required"),
    }),
  }),

  addUserSchema: Yup.object().shape({
    company_user_name: Yup.string().required("Required"),
    mobileno: Yup.string().required("Required"),
    email_id: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    role_id: Yup.string().required("Required"),
    language: Yup.string().required("Required"),
    company_id: Yup.string().required("Required"),
    consignor_group_id: Yup.string().required("Required"),
    // branch_list:Yup.array()
    // .required('Array is required')
  }),

  addSupplierSchema: Yup.object().shape({
    name: Yup.string().required("Required *"),
    as_of_date: Yup.string().required("Required *"),
    gst_treatment_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    gst_state: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    // gst_treatment_id: Yup.string().required("Required *"),
    // gst_state: Yup.string().required("Required *"),
  }),

  createBranch: Yup.object().shape({
    branch: Yup.string().required("Required *"),
    gst_treatment_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    gst_state: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    // gst_treatment_id: Yup.string().required("Required *"),
    // gst_state: Yup.string().required("Required *"),
  }),

  createConsignee: Yup.object().shape({
    name: Yup.string().required("Required *"),
    gst_treatment_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    gst_state: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    zone_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    // gst_treatment_id: Yup.string().required("Required *"),
    // gst_state: Yup.string().required("Required *"),
    // zone_id: Yup.string().required("Required *"),
  }),
  createMarketDriverSchema: Yup.object().shape({
    name: Yup.string().required("Required *"),
  }),
  createOwnDriverSchema: Yup.object().shape({
    name: Yup.string().required("Required *"),
    as_of_date: Yup.string().required("Required *"),
  }),

  
  createVehicleSchema: Yup.object().shape({
    vehicle_no: Yup.string()
    .matches(vehicleNumberPattern, 'Please enter a valid vehicle number in the format XX00XX0000')
    .required('Required *'),
    vehicle_type_id_1: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
  }),
  createLrModalSchema: Yup.object().shape({
    party: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
  }),
  createTripSchima: Yup.object().shape({
    branch_id: Yup.string().required("Required *"),
    vehicle_id: Yup.string().required("Required *"),

    supplier_id: Yup.string().when("ownership", {
      is: (ownership) => ownership === 2 || ownership === 3,
      then: () => Yup.string().required("Supplier name is required"),
    }),
    start_kms: Yup.string().when("manage_trip_kms", {
      is: (ownership) => ownership == 1,
      then: () => Yup.string().required("Required"),
    }),
    driver_id: Yup.string().when("ownership", {
      is: (ownership) => ownership === 1,
      then: () => Yup.string().required("Driver is required"),
    }),

    dispatch_date: Yup.string().required("Required *"),
    vehicle_type_id: Yup.string().required("Required *"),
    toLoction: Yup.array().of(
      Yup.object().shape({
        to_city: Yup.string().required("Required *"),
      })
    ),
    fromLoction: Yup.array().of(
      Yup.object().shape({
        from_city: Yup.string().required("Required *"),
      })
    ),
  }),
  addEditLrModalSchema: Yup.object().shape({
    invoice_date: Yup.string().required("Required"),
    from_location: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    to_location: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    branch_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
  }),

  addRouteValidation: Yup.object().shape({
    from_location: Yup.array().of(
      Yup.object().shape({
        from_city: Yup.string().required("Location required"),
        from_state: Yup.string().required("Location required"),
        lat: Yup.string().required("Location required"),
        long: Yup.string().required("Location required").nullable(),
      })
    ),

    to_location: Yup.array().of(
      Yup.object().shape({
        to_city: Yup.string().required("Location required"),
        to_state: Yup.string().required("Location required"),
        lat: Yup.string().required("Location required"),
        long: Yup.string()
          .required("Location required")
          .required("email required")
          .nullable(),
      })
    ),
    route_name: Yup.string().required("Required"),
    route_code: Yup.string().required("Required"),
  }),

  addNewLrSeriesModalSchema: Yup.object().shape({
    prefix: Yup.string().required("Required"),
    next_number: Yup.string().required("Required"),
  }),
  selectUnitLrModalSchema: Yup.object().shape({
    unit_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
  }),
  addItemInLrSchema: Yup.object().shape(
    {
      name: Yup.string().required("Required"),
      // material_code: Yup.string().required("Required"),
      // hsn_code: Yup.string().required("Required"),
      material_category_id: Yup.object().shape({
        label: Yup.string().required("Required"),
      }),
      qty_type: Yup.object().shape({
        label: Yup.string().required("Required"),
      }),
      weight: Yup.string().when("weight", {
        is: (exist) => exist,
        then: () =>
          Yup.string()
            .min(1)
            .max(8)
            .matches(
              /^\d+(\.\d{1,2})?$/,
              "Only numbers with up to two decimal places are allowed"
            )
            .required("Required"),
      }),
    },
    [["weight", "weight"]]
  ),
  addItemGroupInLrSchema: Yup.object().shape({
    name: Yup.string().required("Required"),
    // hsn_code: Yup.string().required("Required"),
  }),
  addPartyPointSchema: Yup.object().shape({
    date: Yup.string().required("Required"),
    // notes: Yup.string().required("Required"),
  }),
  addPodReceviedSchema: Yup.object().shape({
    date: Yup.string().required("Required"),
    file: Yup.array()
      .of(
        Yup.object().shape({
          path: Yup.string().required("Required"),
        })
      )
      .min(1, "At least 1 Attachement Required * "),
  }),
  addTotalFreigthSchema: Yup.object().shape({
    amount: Yup.string().required("Required *"),
    freight_type: Yup.string().required("Required *"),
    qty: Yup.string().required("Required *"),
    rate: Yup.string().required("Required *"),
    // tds_diduction: Yup.string().required("Required *"),
    // isChecked: Yup.boolean(),
    // tds_diduction: Yup.number().when("isChecked", {
    //   is: (test) => test === true, // Validation will apply when the checkbox is true
    //   then: () =>
    //     Yup.string()
    //       .required("required"),
    // }),
  }),

  addFreigthSchema: Yup.object().shape({
    sub_category: Yup.string().required("Required *"),
    balance: Yup.number()
      .required("Balance is required")
      .positive("Balance must be a positive number"),
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be a positive number")
      .transform((value, originalValue) => {
        if (originalValue === "") {
          return null;
        }
        return value;
      })
      .when("balance", (balance, schema) =>
        schema.test({
          test: function (value) {
            console.log(value, balance);
            return value <= balance;
          },
          message: "Amount must not be greater than balance",
        })
      ),
    txn_date: Yup.string().required("Required *"),

    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().required("Required *"),
    isCheck: Yup.boolean(),
    vendor_account: Yup.string().when("isCheck", {
      is: (test) => test === true,
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
  }),

  addChargeSchema: Yup.object().shape({
    sub_category: Yup.string().required("Required *"),

    amount: Yup.string().required("Amount is required"),
    txn_date: Yup.string().required("Required *"),
    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().when("account_sub_category", {
      is: (val) => val != "11",
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    isCheck: Yup.boolean(),
    vendor_account: Yup.string().when("isCheck", {
      is: (test) => test === true,
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
  }),

  addAccountSchema: Yup.object().shape({
    confirm_account_number: Yup.string()
      .oneOf([Yup.ref("account_number"), null], "Account numbers must match")
      .required("Please confirm your account number"),

    account_type: Yup.number().required("Account type is required"),
    bank_name: Yup.string().when("account_type", {
      is: (test) => test === 1,
      then: () => Yup.string().required("Bank name is required"),
      // otherwise: Yup.string().notRequired(),
    }),
    account_name: Yup.string().when("account_type", {
      is: (test) => test === 1,
      then: () => Yup.string().required("Account name is required"),
    }),
    account_number: Yup.string().when("account_type", {
      is: (test) => test === 1,
      then: () => Yup.string().required("Account number is required"),
    }),
    ifsc_code: Yup.string().when("account_type", {
      is: (test) => test === 1,
      then: () => Yup.string().required("IFSC code is required"),
    }),
    confirm_account_number: Yup.string().when("account_type", {
      is: (test) => test === 1,
      then: () =>
        Yup.string()
          .oneOf(
            [Yup.ref("account_number"), null],
            "Account numbers must match"
          )
          .required("Please confirm your account number"),
    }),
    upi_id: Yup.string().when("account_type", {
      is: (test) => test !== 1,
      then: () => Yup.string().required("UPI ID is required"),
    }),
  }),

  createVendersSchema: Yup.object().shape({
    name: Yup.string().required("Required *"),
    as_of_date: Yup.string().required("Required *"),
    gst_treatment_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    gst_state: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
  }),
  addClaimFullSchema: Yup.object().shape({
    sub_category: Yup.string().required("Required *"),
    amount: Yup.string().required("Required *"),
    txn_date: Yup.string().required("Required *"),

    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().when("account_sub_category", {
      is: (val) => val != "10",
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    expense_category: Yup.string().required("Required *"),
    // party_amount: Yup.string().required("Required *"),
    // party_txn_date: Yup.string().required("Required *"),
    // party_account_sub_category: Yup.string().required("Required *"),
    // party_account_id: Yup.string().when("party_account_sub_category", {
    //   is: (val) => val != "10",
    //   then: () => Yup.string().required("Required *"),
    //   // otherwise: Yup.string(),
    // }),

    // party_expense_category: Yup.string().required("Required *"),
  }),
  addClaimSchema: Yup.object().shape({
    sub_category: Yup.string().required("Required *"),
    balance: Yup.number()
      .required("Balance is required")
      .positive("Balance must be a positive number"),
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be a positive number")
      .transform((value, originalValue) => {
        if (originalValue === "") {
          return null;
        }
        return value;
      })
      .when("balance", (balance, schema) =>
        schema.test({
          test: function (value) {
            console.log(value, balance);
            return value <= balance;
          },
          message: "Amount must not be greater than balance",
        })
      ),
    txn_date: Yup.string().required("Required *"),

    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().when("account_sub_category", {
      is: (val) => val != "10" && val != "11",
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    supplier_account: Yup.boolean(),
    vendor_bank_account: Yup.string().when("supplier_account", {
      is: (test) => test === true,
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    expense_category: Yup.string().required("Required *"),
  }),

  addClaimFullConsignorSchema: Yup.object().shape({
    sub_category: Yup.string().required("Required *"),
    amount: Yup.string().required("Required *"),
    txn_date: Yup.string().required("Required *"),

    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().when("account_sub_category", {
      is: (val) => val != "10" && val != "11",
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    expense_category: Yup.string().required("Required *"),
    // party_amount: Yup.string().required("Required *"),
    // party_txn_date: Yup.string().required("Required *"),
    // party_account_sub_category: Yup.string().required("Required *"),
    // party_account_id: Yup.string().when("party_account_sub_category", {
    //   is: (val) => val != "10" && val != "11",
    //   then: () => Yup.string().required("Required *"),
    //   // otherwise: Yup.string(),
    // }),
    supplier_account: Yup.boolean(),
    vendor_bank_account: Yup.string().when(
      ["sub_category", "supplier_account"],
      {
        is: (sub_category, supplier_account) =>
          sub_category == 2 && supplier_account === true,
        then: () => Yup.string().required("Required *"),
        // otherwise: Yup.string(),
      }
    ),
    // party_expense_category: Yup.string().required("Required *"),
  }),
  addClaimConsignorSchema: Yup.object().shape({
    sub_category: Yup.string().required("Required *"),
    amount: Yup.string().required("Required *"),
    txn_date: Yup.string().required("Required *"),

    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().when("account_sub_category", {
      is: (val) => val != "10" && val != "11",
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),

    expense_category: Yup.string().required("Required *"),
  }),

  addFuelSchema: Yup.object().shape({
    sub_category:Yup.string().required("Required *"),
    fuel_station_id: Yup.string().when('sub_category', {
      is: (i) => i == 1,
      then:()=> Yup.string().required("Fuel station is required "),
      // otherwise: ()=>Yup.string().notRequired(),
    }),
    account_id: Yup.string().when('sub_category', {
      is: (i) => i == 3,
      then: ()=>Yup.string().required("Account ID is required Cash"),
      // otherwise: Yup.string().notRequired(),
    }),
    // fuel_station_id: Yup.string().required("Required *"),
    fuel_ltr: Yup.string().required("Required *"),
    fuel_rate: Yup.string().required("Required *"),
    amount: Yup.string().required("Required *"),
    date: Yup.string().required("Required *"),
    slip_no: Yup.string().required("Required *"),
  }),
  createContractSchema: Yup.object().shape({
    name: Yup.string().required("Required *"),
    // invoice_format: Yup.object().shape({
    //   label: Yup.string().required("Required"),
    // }),
  }),
  createRateSchema: Yup.object().shape({
    consignor_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    contract_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    rate_type: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    amount: Yup.string().required("Required *"),
  }),
  addEditZoneSchema: Yup.object().shape({
    name: Yup.string().required("Required"),
  }),
  addEditChartOfAccountSchema: Yup.object().shape({
    name: Yup.string().required("Required"),
    category_id: Yup.string().required("Required"),
    sub_category_id: Yup.string().required("Required"),
    code: Yup.string().required("Required"),
    desc: Yup.string().required("Required"),
  }),
  createBillRaiseSchema: Yup.object().shape({
    invoice_number: Yup.string().required("Required *"),
    invoice_date: Yup.string().required("Required *"),
    due_date: Yup.string().required("Required *"),
    consignor_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
    branch_id: Yup.object().shape({
      label: Yup.string().required("Required"),
    }),
  }),

  endTripSchema: Yup.object().shape({
    end_date: Yup.string().required("Required *"),
    start_kms: Yup.string().when("manage_trip_kms", {
      is: 1,
      then: () => Yup.string().required("Required *"),
      otherwise: () => Yup.string().nullable(),
    }),
    end_kms: Yup.string().when(["start_kms", "manage_trip_kms"], {
      is: (start_kms, manage_trip_kms) =>
        manage_trip_kms === 1 && start_kms > "0",
      then: () =>
        Yup.string()
          .required("Required *")
          .test(
            "is-greater-than-start",
            "End kms must be greater than Start kms",
            function (value) {
              const { start_kms } = this.parent;
              return Number(value) > Number(start_kms);
            }
          ),
      otherwise: () => Yup.string().nullable(),
    }),
  }),

  ewayBillSchima: Yup.object().shape({
    end_date: Yup.string().required("Required *"),
    start_date: Yup.string().required("Required *"),
    eway_bill_number: Yup.string()
      .required("Required *")
      .matches(/^\d{12}$/, "E-way bill number must be exactly 12 digits"),
  }),

  addOwnChargeSchema: Yup.object().shape({
    sub_category: Yup.string().required("Required *"),
    expense_category: Yup.string().required("Required *"),
    amount: Yup.string().required("Amount is required"),
    txn_date: Yup.string().required("Required *"),
    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().when("account_sub_category", {
      is: (val) => val != "10" && val != "11",
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    isCheck: Yup.boolean(),
    vendor_account: Yup.string().when("isCheck", {
      is: (test) => test === true,
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
  }),

  addClaimOwnFullSchema: Yup.object().shape({
    sub_category: Yup.string().required("Required *"),
    amount: Yup.string().required("Required *"),
    txn_date: Yup.string().required("Required *"),

    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().when("account_sub_category", {
      is: (val) => val != "10" && val != "11",
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    expense_category: Yup.string().required("Required *"),
    // party_amount: Yup.string().required("Required *"),
    // party_txn_date: Yup.string().required("Required *"),
    // party_account_sub_category: Yup.string().required("Required *"),
    // party_account_id: Yup.string().when("party_account_sub_category", {
    //   is: (val) => val != "10" && val != "11",
    //   then: () => Yup.string().required("Required *"),
    //   // otherwise: Yup.string(),
    // }),

    // party_expense_category: Yup.string().required("Required *"),
  }),
  addClaimOwnSchema: Yup.object().shape({
    sub_category: Yup.string().required("Required *"),

    amount: Yup.string().required("Required *"),
    txn_date: Yup.string().required("Required *"),

    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().when("account_sub_category", {
      is: (val) => val != "11",
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    supplier_account: Yup.boolean(),
    vendor_bank_account: Yup.string().when("supplier_account", {
      is: (test) => test === true,
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    expense_category: Yup.string().required("Required *"),
  }),

  addOtherSchema: Yup.object().shape({
    sub_category: Yup.string().required("Required *"),

    amount: Yup.string().required("Required *"),
    txn_date: Yup.string().required("Required *"),

    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().when("account_sub_category", {
      is: (val) => val != "10" && val != "11",
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    supplier_account: Yup.boolean(),
    vendor_bank_account: Yup.string().when("supplier_account", {
      is: (test) => test === true,
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
    expense_category: Yup.string().required("Required *"),
  }),

  addPayDriverSchema: Yup.object().shape({
    expense_category: Yup.string().required("Required *"),
    amount: Yup.string().required("Amount is required"),
    txn_date: Yup.string().required("Required *"),
    account_sub_category: Yup.string().required("Required *"),
    account_id: Yup.string().required("Required *"),
    vendor_bank_account: Yup.string().when("account_sub_category", {
      is: (test) => test == 2,
      then: () => Yup.string().required("Required *"),
      // otherwise: Yup.string(),
    }),
  }),

  addAddressBook: Yup.object().shape({
    name: Yup.string().required("Required *"),
    category: Yup.string().required("Amount is required"),
    // associate_party_id: Yup.string().when("category", {
    //   is: (test) => test == 1|| test == 2 || test == 3,
    //   then: () => Yup.string().required("Required *"),
    // }),
    // contact_no: Yup.string().required("Required *"),
    // email_id: Yup.string().required("Required *"),
    // address_line1: Yup.string().required("Required *"),

    // state: Yup.object().shape({
    //   label: Yup.string().required("city is required"),
    // }),
    // city: Yup.object().shape({
    //   label: Yup.string().required("city is required"),
    // }),
    // pincode: Yup.string().required("Required *"),
    // code: Yup.string().required("Required *"),
    // zone_id: Yup.string().required("Required *"),
    // branch_id: Yup.string().required("Required *"),
  }),

  addAddressBookContinue: Yup.object().shape({
    name: Yup.string().required("Required *"),
    category: Yup.string().required("Amount is required"),
    email_id: Yup.string()
    .nullable()  // Allows empty values
    .notRequired()  // Makes the field optional
    .email("Invalid email address"),  // Validat
  }),

  addPoSchema: Yup.object().shape({
    po_date: Yup.string().required("Required *"),
    from_location: Yup.string().required("Required *"),
    to_location: Yup.string().required("Required *"),
  }),

  addPostIndentSchima: Yup.object().shape({
    indent_date: Yup.string().required("Required *"),
    indent_date: Yup.string().required("Required *"),
    lorry_type_id: Yup.string().required("Required *"),
    vehicle_type_id: Yup.string().required("Required *"),
    weight: Yup.string().required("Required *"),
    material_type_id: Yup.string().required("Required *"),

    freight_amount: Yup.string().when("freight_type", {
      is: (ownership) => ownership == 4,
      then: () => Yup.string().required("Required"),
    }),
    freight_rate: Yup.string().when("freight_type", {
      is: (ownership) => ownership == 6,
      then: () => Yup.string().required("Required"),
    }),
    payment_percentage: Yup.string().when("payment_terms", {
      is: (ownership) => ownership == 1,
      then: () => Yup.string().required("Required"),
    }),

    branch_id: Yup.string().required("Required *"),
    // assign_to:Yup.string().required("Required *"),

    // connection_id: Yup.string().when('is_allow_visibility', {
    //   is: (ownership) => ownership == 1,
    //   then:()=> Yup.string().required('Required'),

    // }),

    toLoction: Yup.array().of(
      Yup.object().shape({
       
        to_city: Yup.string().required(" Required"),
        to_state: Yup.string().required(" Required"),
        lat: Yup.number().nullable(),  // If lat can be null
        long: Yup.number().nullable(), // If long can be null
        po_list: Yup.array().when('id', {
          is: (id) => !!id, // Check if id exists
          then:()=> Yup.array().of(
            Yup.object().shape({
              from_location: Yup.string().required("From Location is Required"),
              to_location: Yup.string().required("Pickup and Drop is Required"),
              from_state: Yup.string().required("From State is required"),
              to_state: Yup.string().required("To State is required"),
            
            })
          ),
          otherwise:()=> Yup.array().notRequired(), // If no id, skip validation
        }),
      })
    ),

    // toLoction: Yup.array().of(
    //   Yup.object().shape({
    //     to_city: Yup.string().required("Required *"),
    //   })
    // ),
    fromLoction: Yup.array().of(
      Yup.object().shape({
        from_city: Yup.string().required("Required *"),
      })
    ),

    responses: Yup.string().required("Required *"),
  }),

  addNewConnection: Yup.object().shape({
    name: Yup.string().required("Required"),
  }),

  planImportSchema: Yup.object().shape({
    consignor_id: Yup.string().required("Required"),
    contract_id: Yup.string().required("Required"),
    file: Yup.string().required("Required"),
  }),

  createBidSchema: Yup.object().shape({
    amount: Yup.string().required("Required"),
  }),


  cashSchema : Yup.object().shape({
    cash_name: Yup.string().required('Cash Name is required'),
    cash_opting_bal: Yup.string().required('Opening Balance is required'),
    cash_credit_or_debit: Yup.string().required('Credit or Debit is required'),
  }),

  bankSchema :Yup.object().shape({
    holder_name: Yup.string().required('Holder Name is required'),
    number: Yup.string().required('Account Number is required'),
    ifsc: Yup.string().required('IFSC Code is required'),
    bank: Yup.string().required('Bank Name is required'),
    bank_opening_balance: Yup.string().required('Opening Balance is required'),
    bank_credit_or_debit: Yup.string().required('Credit or Debit is required'),
  }),
  
  otherSchema :Yup.object().shape({
    other_name: Yup.string().required('Other Account Name is required'),
    other_opening_balance: Yup.string().required('Opening Balance is required'),
    other_credit_or_debit: Yup.string().required('Credit or Debit is required'),
  }),
  
fuelSchema: Yup.object().shape({
    fuel_card_number: Yup.string().required('Fuel Card Number is required'),
    associate_vehicle_id: Yup.string().required('Vehicle ID is required'),
    fuel_opening_balance: Yup.string().required('Opening Balance is required'),
    fuel_credit_or_debit: Yup.string().required('Credit or Debit is required'),
    // fuel_card_master_id: Yup.string().required('Fuel Card Master ID is required'),
  }),

  fastagSchema: Yup.object().shape({
    fastag_id_number: Yup.string().required('Fastag Number is required'),
    fastag_associate_vehicle_id: Yup.string().required('Vehicle ID is required'),
    fastag_opening_balance: Yup.string().required('Opening Balance is required'),
    fastag_credit_or_debit: Yup.string().required('Credit or Debit is required'),
    // fastag_parent_account_id: Yup.string().required('Fuel Card Master ID is required'),
  }),

  addExpenseAccountSchema: Yup.object().shape({
    type: Yup.string().required("Required *"),
    date: Yup.string().required("Required *"),
    // amount: Yup.string().required("Required *"),
    account_id: Yup.string().when("account_sub_category", {
      is: (ownership) => ownership != 14,
      then: () => Yup.string().required("Required"),
    }),
    account_sub_category: Yup.string().required("Required *"),
    invoice_number: Yup.string().required("Required *"),
    invoice_date: Yup.string().required("Required *"),
    place_of_supply: Yup.object().shape({
      label: Yup.string().required("State is required"),
    }),
    expenseArray: Yup.array().of(
      Yup.object().shape({
        expense_category: Yup.string().required("Required *"),
        gst_percentage: Yup.string().when('is_gst', {
          is: 1,
          then: Yup.string().required("Required when GST is applicable"),
          otherwise: Yup.string().notRequired(),
        }),
        amount: Yup.string().required("Required *"),
        description: Yup.string().when('is_gst', {
          is: 1,
          then: Yup.string().required("Required when GST is applicable"),
          otherwise: Yup.string().notRequired(),
        }),
        hsn_code: Yup.string().when('is_gst', {
          is: 1,
          then: Yup.string().required("Required when GST is applicable"),
          otherwise: Yup.string().notRequired(),
        }),
      })
    ).when('is_gst', {
      is: (i)=>i==false,
      then: ()=>Yup.array().of(
        Yup.object().shape({
          expense_category: Yup.string().required("Required *"),
          amount: Yup.string().required("Required *"),
        })
      ),
      otherwise:()=> Yup.array().of(
        Yup.object().shape({
          expense_category: Yup.string().required("Required *"),
          gst_percentage: Yup.string().required("Required when GST is applicable"),
          amount: Yup.string().required("Required *"),
          description: Yup.string().required("Required *"),
          hsn_code: Yup.string().required("Required *"),
        })
      )
    }),
  }),
  
//////////////other Project /////////////////

loginSchema: Yup.object().shape({
  email:Yup.string()
  .email("Invalid email address")
  .required("Email is required"),
  password: Yup.string().required('Password is required'),
 
}),

eventSchema:Yup.object().shape({
  event_name: Yup.string().required('Event Name is required'),
  start_date: Yup.string().required('Start Date is required'),
  end_date: Yup.string().required('End Date is required'),
}),

floorSchema:Yup.object().shape({
  name: Yup.string().required('Name is required'),
  
}),

roomSchema:Yup.object().shape({
  floor: Yup.string().required('Event Name is required'),
  room_number: Yup.string().required('Start Date is required'),
}),

guestSchema :Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email:Yup.string()
  .email("Invalid email address")
  .required("Email is required"),
  mobile_number: Yup.string()
    .min(10, "Mobile number must be at least 10 characters")
    .required("Mobile number is required"),
  user_type: Yup.string().required('User type is required'),
  gender: Yup.string().required('Gender is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  address: Yup.string().required('Address is required'),
}),

addRoomBookingSchema:Yup.object().shape({
  event: Yup.string().required('Event is required'),
  name: Yup.string().required('Name is required'),
  email:Yup.string()
  .email("Invalid email address")
  .required("Email is required"),
  mobile_number: Yup.string()
    .min(10, "Mobile number must be at least 10 characters")
    .required("Mobile number is required"),

  gender: Yup.string().required('Gender is required'),
  state: Yup.string().required('State is required'),
  city: Yup.string().required('City is required'),
  address: Yup.string().required('Address is required'),
  room:Yup.string().required('Room is required'),
  floor:Yup.string().required('floor is required'),
total_guests:Yup.string().required('Total Guests is required'),
}),

uploadFileSchema:Yup.object().shape({
  document_file: Yup.string().required('Event Name is required'),
 
}),

keyRequestSchema :Yup.object().shape({
  event: Yup.string().required('Event is required'),
  room_booking:Yup.string().required('Event is required'),
  guest_name:Yup.string().required('Event is required'),
  mobile_number:Yup.string().required('Event is required'),
}),
};
