import { UserContext } from "context";
import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { AiOutlineEye } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegTrashCan } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { deleteUserData } from "_services";
import {
  ButtonHeading,
  CommonModal,
  CommonPagination,
  CustomPagination,
} from "components";
import BootstrapTable from "components/BootstrapTable";
import { deleteGuest, getGuest } from "_services/guestServices";
import { AddGuestModal } from "modals";
import MastersFilterModal from "modals/MastersFilterModal";

const Guest = () => {
  const userTypeList = [
    { label: "ADMIN", value: 1 },
    { label: "GUEST", value: 2 },
    { label: "STAFF", value: 3 },
  ];

  const genderList = [
    { label: "MALE", value: 1 },
    { label: "FEMALE", value: 2 },
  ];

  const { masterData } = useContext(UserContext);
  const navigate = useNavigate();
  const loadingBarRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [allGuestList, setAllGuestList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [addressId, setAddressId] = useState("");
  const [eventView, setEventView] = useState(false);

  const [filter, setFilter] = useState({
    mobile_number:"",
    gender:"",
    state:"",
    city:""
  });

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const columns = [
    // { header: "", accessor: "serial" },
    { header: "Name", accessor: "name" },
    { header: "Type", accessor: "category" },
    { header: "Gender", accessor: "genderValue" },
    { header: "City", accessor: "city" },
    { header: "State", accessor: "state" },

    { header: "Mobile", accessor: "mobile_number" },
    { header: "Email", accessor: "email" },
    { header: "Address", accessor: "address" },
    { header: "", accessor: "action" },
    // { header: "Status", accessor: "status" },
  ];

  useEffect(() => {
    getGuestList();
  }, [filter]);

  const getGuestList = async (url = null) => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart();
      }
      const filterParams = new URLSearchParams();

      Object.keys(filter).forEach(key => {
        if (filter[key]) {
          filterParams.append(key, filter[key]);
        }
      });
      console.log(filter)

    const urlmodify = `${url}?${filterParams.toString()}`;

      const res = await getGuest(url,filterParams.toString());

      if (res?.status === 200) {
        setAllGuestList(res?.results);
        setNextPageUrl(res?.next);
        setPrevPageUrl(res?.previous);
        setTotalItems(res?.count);
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error(error);
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }
    }
  };

  const deleteHandler = (item) => {
    setIsDelete(item);
  };

  const statusChangeHnadle = async (data, index) => {};

  const handleStatusEvent = async (data, index) => {
    statusChangeHnadle(data, index);
    const list = [...allGuestList];
    list[index].active = data?.active === 1 ? 0 : 1;
    setAllGuestList(list);
  };

  const rowListData = (data) => {
    return data?.map((item, i) => ({
      ...item,
      serial: (
        <React.Fragment>
          <div className="signDropdown">
            <button className="dropbtn11">
              <BsThreeDotsVertical />
            </button>
            <div className="dropdownContent">
              <Link
                onClick={() => {
                  setEventView(item?.id);
                  setAddressId(item?.id);
                  setShowOffcanvas(true);
                }}
                className="_avPointer"
                style={{ textDecoration: "none" }}
              >
                <span className="_samAnchor">
                  <span className="viewIcon_avt">
                    <AiOutlineEye />
                  </span>
                  &nbsp; View
                </span>
              </Link>
              <Link
                onClick={() => {
                  setAddressId(item?.id);
                  setShowOffcanvas(true);
                }}
                className="_avPointer"
                style={{ textDecoration: "none" }}
              >
                <span className="_samAnchor">
                  <span className="viewIcon_avt">
                    <FiEdit />
                  </span>
                  &nbsp; Edit
                </span>
              </Link>
              {/* <div
                    
                    className='_avPointer'
                  >
                    <span className='_samAnchor'>
                      <span className='editIcon_avt'>
                        <FiEdit />
                      </span>
                      Edit
                    </span>
                  </div> */}

              <div onClick={(e) => deleteHandler(item)} className="_avPointer">
                <span className="_samAnchor">
                  <span className="deleteIcon_avt">
                    <FaRegTrashCan color="red" />
                  </span>
                  Delete
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),

      zoneName: zoneList?.find((i) => i?._id === item?.zone_id)?.name || "",
      supplier_name: (
        <Link style={{ textDecoration: "none" }}>
          {item?.company_user_name ? item?.company_user_name : "-"}
        </Link>
      ),
      contact_number: (
        <React.Fragment>
          {item?.user_id?.mobile_no ? item?.user_id?.mobile_no : "-"}
        </React.Fragment>
      ),
      category: (
        <React.Fragment>
          {userTypeList.find((fx) => fx?.value == item?.user_type)?.label}
        </React.Fragment>
      ),
      genderValue: (
        <React.Fragment>
          {genderList.find((fx) => fx?.value == item?.gender)?.label}
        </React.Fragment>
      ),
      party_group: (
        <React.Fragment>
          {item?.consignor_group_id?.name
            ? item?.consignor_group_id?.name
            : "-"}
        </React.Fragment>
      ),
      // city: (
      //   <React.Fragment>
      //     {item?.city?.label ? item?.city?.label : "-"}
      //   </React.Fragment>
      // ),
      action: (
        <div
          className="_anvSwitcherNew form-check form-switch"
          style={{ width: "125px" }}
        >
          <span
            className="viewIcon_avt" style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/guest/view/${item?.id}/${true}`);
            }}
          >
            <AiOutlineEye color="green" />
          </span>
          <span
            className="viewIcon_avt"  style={{ cursor: "pointer" }}
            onClick={() => {
              navigate(`/guest/edit/${item?.id}`);
            }}
          >
            <FiEdit color="blue" />
          </span>
          <span className="deleteIcon_avt"  style={{ cursor: "pointer" }} onClick={(e) => deleteHandler(item)}>
            <FaRegTrashCan color="red" />
          </span>
        </div>
      ),
      status: (
        <div
          className="_anvSwitcherNew form-check form-switch"
          style={{ width: "125px" }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={item?.active}
            onChange={() => handleStatusEvent(item, i)}
          />
          <span
            className={
              item?.active
                ? "active_label_masters_status"
                : "disabled_label_masters_status"
            }
          >
            {item?.active ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      balance: item?.balance ? (
        <Link
          to={`/account/supplier/details/${item?._id}?tab=4`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color:
                item.balance < 0
                  ? "#dc3545"
                  : item.balance > 0
                  ? "#28a745"
                  : "#1450ec",
            }}
          >
            <span></span>
            <span>{item.balance}</span>
          </div>
        </Link>
      ) : (
        <Link
          to={`/account/supplier/details/${item?._id}?tab=4`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color: "#1450ec",
            }}
          >
            <span></span>
            <span>0</span>
          </div>
        </Link>
      ),
    }));
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };

  const submitDeleteSupplier = async () => {
    setLoading(true);
    try {
      loadingBarRef.current?.continuousStart();
      let object = {
        id: isDelete?.id,
      };

      const res = await deleteGuest(object);
      if (res?.status == 200) {
        toast.success(`${res?.message}`);
        getGuestList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.error(res?.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const callBack = () => {
    getGuestList();
    setAddressId("");
    setEventView(false);
  };

  const handleNextPage = () => {
    if (nextPageUrl) {
      getGuestList(nextPageUrl);
    }
  };

  const handlePrevPage = () => {
    if (prevPageUrl) {
      getGuestList(prevPageUrl);
    }
  };

  const callBackNext = () => {
    handleNextPage();
  };

  const callBackPre = () => {
    handlePrevPage();
  };

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteSupplier,
    isLoadding: loading,
  };

  let eventProps = {
    isOpen: showOffcanvas,
    closeModal: (e) => {
      setAddressId("");
      setShowOffcanvas(false);
      setEventView(false);
    },

    modalMainClass: "_dashCommon_Modal _smModal",
    loading: loading,
    modalTitle: addressId
      ? eventView
        ? "View Customer"
        : "Update Customer"
      : "Add Customer",
    submitTitle: addressId
      ? eventView
        ? "Customer"
        : "Update Customer"
      : "Add Customer",
    callBack: callBack,
    addressId: addressId,
    eventView: eventView,
  };

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"Guest"}
        onClick={() => navigate("/guest/create")}
        buttonTitle={"Add Guest"}
        showSearchFilter={{
          search: false,
          filterOption: true,
          filterCount: true,
        }}
        onClickFilterModal={(e) => setShowFilterModal(true)}
        filter={filter}
        setFilter={setFilter}
      />

      <div className="_tabWith_outTab">
        <BootstrapTable
          columns={columns}
          data={rowListData(allGuestList?.length ? allGuestList : [])}
          isLoading={loading}
        />

        {allGuestList?.length && (
          <div className="paginationFt_avt ">
            {" "}
            <CommonPagination
              totalItems={totalItems}
              itemsPerPage={itemsPerPage}
              handleNextPage={callBackNext}
              handlePrevPage={callBackPre}
            />
          </div>
        )}
      </div>

      {/* <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showOffcanvas ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showOffcanvas ? "visible" : "hidden" }}
      ></div>

      <div className={showOffcanvas ? "offBack_drop" : ""}></div> */}

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showFilterModal ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? "visible" : "hidden" }}
      >
        {showFilterModal && (
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
            isShow={{mobile_number:true,gender:true,state:true,city:true}}
            name="guest"
          />
        )}
      </div> 
       <div className={showFilterModal ? "offBack_drop" : ""}></div>

      {showOffcanvas && <AddGuestModal {...eventProps} />}

      {isDelete?.id && (
        <CommonModal {...propsObject}>
          <p className="p-3"> Do you really want to delete {isDelete?.name} </p>
        </CommonModal>
      )}
    </div>
  );
};

export default Guest;
