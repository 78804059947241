import { API, axiosInstance } from "lib";

export const createMasterEvent = (data = {}) => {
  return axiosInstance
    .post(API.EVENT, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getEvent = (data) => {

  return axiosInstance
    .get(data?data:API.EVENT, {})
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getAllEvent = (data) => {
  const queryParams = new URLSearchParams(data).toString();
  return axiosInstance
  .get(`${API.EVENT}?${queryParams}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};


export const deleteMasterEvent = (data = {}) => {
  return axiosInstance
    .delete(`${API.EVENT}/${data?.id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};


export const getSingleMasterEvent = (data = {}) => {
  return axiosInstance
    .get(`${API.EVENT}/${data}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const updateMasterEvent = (data = {}) => {
  return axiosInstance
    .patch(`${API.EVENT}/${data?.id}`, data.payload)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const changeEventStatus = (data = {}) => {
  return axiosInstance
    .patch(`${API.EVENT}/${data?.id}`, data.payload)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};


export const createMasterFloor = (data = {}) => {
  return axiosInstance
    .post(API.FLOOR, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};


export const getFloor = (data) => {
  return axiosInstance
  .get(data?data:API.FLOOR, {})
    .then((response) => {
    
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getAllFloor = (data) => {
  const queryParams = new URLSearchParams(data).toString();
  return axiosInstance
  .get(`${API.FLOOR}?${queryParams}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getSingleMasterFloor = (data = {}) => {
  return axiosInstance
    .get(`${API.FLOOR}/${data}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteMasterFloor = (data = {}) => {
  return axiosInstance
    .delete(`${API.FLOOR}/${data?.id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const updateMasterFloor = (data = {}) => {
  return axiosInstance
    .patch(`${API.FLOOR}/${data?.id}`, data.payload)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const createMasterRoom = (data = {}) => {
  return axiosInstance
    .post(API.ROOM, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getRoom = (data) => {
  return axiosInstance
  .get(data?data:API.ROOM, {})
    .then((response) => {
     
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getAllRoom = (data) => {
  const queryParams = new URLSearchParams(data).toString();
  return axiosInstance
  .get(`${API.ROOM}?${queryParams}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getRoomAvailable = (data) => {
  const queryParams = new URLSearchParams(data).toString();
  return axiosInstance
  .get(`${API.ROOM_AVL}?${queryParams}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getSingleMasterRoom = (data = {}) => {
  return axiosInstance
    .get(`${API.ROOM}/${data}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteMasterRoom = (data = {}) => {
  return axiosInstance
    .delete(`${API.ROOM}/${data?.id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const updateMasterRoom = (data = {}) => {
  return axiosInstance
    .patch(`${API.ROOM}/${data?.id}`, data.payload)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};


///////////Booking ....\\\\

export const getRoomBooking = (data,filter) => {
  return axiosInstance
  .get(data?`${data}?${filter}`:`${API.ROOM_BOOING}?${filter}`, {})
    .then((response) => {
     
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};



export const createRoomBooking = (data = {}) => {
  return axiosInstance
    .post(API.ROOM_BOOING, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      console.log(err);
    });
};

export const updateRoomBooking = (data = {}) => {
  return axiosInstance
    .patch(`${API.ROOM_BOOING}/${data?.id}`, data.payload)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteRoomBook = (data = {}) => {
  return axiosInstance
    .delete(`${API.ROOM_BOOING}/${data?.id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getSingleRoomBooking = (data = {}) => {
  return axiosInstance
    .get(`${API.ROOM_BOOING}/${data}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getAllRoomBooking = (data) => {
  const queryParams = new URLSearchParams(data).toString();
  return axiosInstance
  .get(`${API.ROOM_BOOING}?${queryParams}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getKeyRequest = (data,filter) => {
  return axiosInstance
  .get(data?`${data}?${filter}`:`${API.KEY_REQUEST}?${filter}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.error("Axios Error:", err.message);
      if (err.response) {
        console.error("Server Response:", err.response.status, err.response.data);
      }
      throw err;
    });
};

export const getSingleKeyRequest = (data = {}) => {
  console.log(data)
  return axiosInstance
    .get(`${API.KEY_REQUEST}/${data}`, {})
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const createKeyRequest = (data = {}) => {
  return axiosInstance
    .post(API.KEY_REQUEST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      console.log(err);
    });
};

export const updateKeyRequest = (data = {}) => {
  return axiosInstance
    .patch(`${API.KEY_REQUEST}/${data?.id}`, data.payload)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const deleteKeyRequest = (data = {}) => {
  return axiosInstance
    .delete(`${API.KEY_REQUEST}/${data?.id}`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};