import { deleteMasterFloor,getFloor } from "_services";

import { ButtonHeading, CommonModal, CommonPagination, CustomPagination} from "components";
import BootstrapTable from "components/BootstrapTable";
import {  AddFloorModal} from "modals";
import MastersFilterModal from "modals/MastersFilterModal";
import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { AiOutlineEye } from "react-icons/ai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegTrashCan } from "react-icons/fa6";
import { FiEdit } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import { UserContext } from "context";


const Floor = () => {
  const { masterData } = useContext(UserContext);
  const navigate = useNavigate();
  const loadingBarRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [allFloorlist, setAllFloorList] = useState([]);
  const [eventView,setEventView] = useState(false)
  const [addressId,setAddressId] = useState('')

  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: "",
    status: 1,
  });

  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);

  const columns = [
   
   
    { header: "Floor Nmae", accessor: "name" },
    { header: "", accessor: "action" },
  ];

  useEffect(() => {
    getFloorList();
  }, []);


  const getFloorList = async (url=null) => {
   
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); 
      }
      const res = await getFloor(url);
    
      if (res?.status === 200) {
      setAllFloorList(res?.results)
      setNextPageUrl(res?.next)
      setPrevPageUrl(res?.previous)
      setTotalItems(res?.count)
    }else{
      toast.error(res.error);
    }
    } catch (error) {
      toast.error(error);
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }
    }
  };

  const editHandler = (data) => {};

  const deleteHandler = (item) => {
    setIsDelete(item)
  };


  const rowListData = (data) => {

    return data?.map((item, i) => ({
      ...item,
      serial: (
        <React.Fragment>
          <div className="signDropdown">
            <button className="dropbtn11">
              <BsThreeDotsVertical />
            </button>
            <div className="dropdownContent">
              <Link
                 onClick={()=>{
                  setEventView(item?.id)
                  setAddressId(item?.id)
                  setShowOffcanvas(true)
                }}
                className="_avPointer"
                style={{ textDecoration: "none" }}
              >
                <span className="_samAnchor">
                  <span className="viewIcon_avt">
                    <AiOutlineEye />
                  </span>
                  &nbsp; View
                </span>
              </Link>
              <Link
                  onClick={()=>{
                    setAddressId(item?.id)
                    setShowOffcanvas(true)
                  }}
                className="_avPointer"
                style={{ textDecoration: "none" }}
              >
                <span className="_samAnchor">
                  <span className="viewIcon_avt">
                    <FiEdit />
                  </span>
                  &nbsp; Edit
                </span>
              </Link>
              {/* <div
                    
                    className='_avPointer'
                  >
                    <span className='_samAnchor'>
                      <span className='editIcon_avt'>
                        <FiEdit />
                      </span>
                      Edit
                    </span>
                  </div> */}

              <div onClick={(e) => deleteHandler(item)} className="_avPointer">
                <span className="_samAnchor">
                  <span className="deleteIcon_avt">
                    <FaRegTrashCan color="red" />
                  </span>
                  Delete
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ),

     
      // zoneName: zoneList?.find((i) => i?._id === item?.zone_id)?.name || "",
      supplier_name: (
        <Link style={{ textDecoration: "none" }}>
          {item?.company_user_name ? item?.company_user_name : "-"}
        </Link>
      ),
      contact_number: (
        <React.Fragment>
          {item?.user_id?.mobile_no ? item?.user_id?.mobile_no : "-"}
        </React.Fragment>
      ),
      category: (
        <React.Fragment>
          {
            masterData?.addressbook_category_list.find(
              (fx) => fx?.id == item?.category
            )?.name
          }
        </React.Fragment>
      ),
      party_group: (
        <React.Fragment>
          {item?.consignor_group_id?.name
            ? item?.consignor_group_id?.name
            : "-"}
        </React.Fragment>
      ),
      city: (
        <React.Fragment>
          {item?.city?.label ? item?.city?.label : "-"}
        </React.Fragment>
      ),
      status: (
        <div
          className="_anvSwitcherNew form-check form-switch"
          style={{ width: "125px" }}
        >
          <input
            className="form-check-input"
            type="checkbox"
            name="actStatus"
            id={`actStatus-${item._id}`}
            autoComplete="off"
            checked={item?.active}
            // onChange={() => handleStatusEvent(item, i)}
          />
          <span
            className={
              item?.active
                ? "active_label_masters_status"
                : "disabled_label_masters_status"
            }
          >
            {item?.active ? "Active" : "Disabled"}
          </span>
        </div>
      ),
      action: (
        <div
          className="_anvSwitcherNew form-check form-switch"
          style={{ width: "125px" }}
        >
          <span
            className="viewIcon_avt" style={{ cursor: "pointer" }}
            onClick={() => {
              setEventView(item?.id);
              setAddressId(item?.id);
              setShowOffcanvas(true);
            }}
          >
            <AiOutlineEye color="green" />
          </span>
          <span
            className="viewIcon_avt"  style={{ cursor: "pointer" }}
            onClick={() => {
              setAddressId(item?.id);
              setShowOffcanvas(true);
            }}
          >
            <FiEdit color="blue" />
          </span>
          <span className="deleteIcon_avt"  style={{ cursor: "pointer" }} onClick={(e) => deleteHandler(item)}>
            <FaRegTrashCan color="red" />
          </span>
        </div>
      ),
      balance: item?.balance ? (
        <Link
          to={`/account/supplier/details/${item?._id}?tab=4`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color:
                item.balance < 0
                  ? "#dc3545"
                  : item.balance > 0
                  ? "#28a745"
                  : "#1450ec",
            }}
          >
            <span>
              
            </span>
            <span>{item.balance}</span>
          </div>
        </Link>
      ) : (
        <Link
          to={`/account/supplier/details/${item?._id}?tab=4`}
          style={{ textDecoration: "none" }}
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              alignItems: "center",
              color: "#1450ec",
            }}
          >
            <span>
              
            </span>
            <span>0</span>
          </div>
        </Link>
      ),
    }));
  };

const callback = () =>{
  setEventView(false)
  getFloorList()
  setAddressId("")
}

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }));
  };


  const submitDeleteSupplier = async () => {
    setLoading(true);
    try {
      loadingBarRef.current?.continuousStart();
      let object = {
        id:isDelete?.id
       };
 
      const res = await deleteMasterFloor(object);
      if (res?.status == 200) {
        toast.success(`${res?.message}`);
        getFloorList()
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.error(res?.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const handleNextPage = () => {
    if (nextPageUrl) {
      getFloorList(nextPageUrl);
    }
  };
  
  const handlePrevPage = () => {
    if (prevPageUrl) {
      getFloorList(prevPageUrl);
    }
  };

  const callBackNext = ()=>{
    handleNextPage()
  }

  const callBackPre = ()=>{
    handlePrevPage()
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteSupplier,
    isLoadding: loading,
  };


  let eventProps = {
    isOpen: showOffcanvas,
    closeModal: (e) => {
      setEventView(false)
      setAddressId("")
      setShowOffcanvas(false)},
    modalMainClass: "_dashCommon_Modal _smModal",
    loading: loading,
    modalTitle: addressId ? (eventView ? "View Floor" : "Update Floor") : "Add Floor",
    submitTitle: addressId ? (eventView ? "Floor" : "Update Floor") : "Add Floor",
    callback:callback,
    addressId:addressId,
    eventView:eventView
  }

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"Floor"}
        onClick={() => setShowOffcanvas(true)}
        buttonTitle={"Add Floor"}
        showSearchFilter={{
          search: false,
          filterOption: true,
          filterCount: true,
        }}
        onClickFilterModal={(e) => setShowFilterModal(false)}
        filter={filter}
        setFilter={setFilter}
      />
      
      <div className="_tabWith_outTab">
        <BootstrapTable
          columns={columns}
          data={rowListData(allFloorlist?.length ? allFloorlist : [])}
          isLoading={loading}
        />

       { allFloorlist?.length && <div className="paginationFt_avt "> <CommonPagination
           totalItems={totalItems}
           itemsPerPage={itemsPerPage}
           handleNextPage={callBackNext}
           handlePrevPage={callBackPre}
        /></div>}
      </div>

     

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          showFilterModal ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? "visible" : "hidden" }}
      >
        {showFilterModal && (
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        )}
      </div>
      <div className={showFilterModal ? "offBack_drop" : ""}></div>

      {isDelete?.id && (
        <CommonModal {...propsObject}>
          <p className="p-3"> Do you really want to delete {isDelete?.name} </p>
        </CommonModal>
      )}

{showOffcanvas
        && (
          <AddFloorModal {...eventProps} />
        )
      }
    </div>
  );
};

export default Floor;
