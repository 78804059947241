import { createMasterEvent, getSingleMasterEvent, updateMasterEvent} from "_services";
import {
  CommonDate,
  CommonInput,
  CommonModal,
} from "components";
import { createEvent } from "features/masterService";
import { errorSchema, utils } from "helper";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../assets/css/modal.css";

const AddEventModal = (props) => {
  const [form, setForm] = useState({
    event_name: "",
    start_date: new Date(),
    end_date: new Date(),
    is_active: true,
  });
  const [initialForm, setInitialForm] = useState({
    event_name: "",
    start_date: new Date(),
    end_date: new Date(),
    is_active: true,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nameRef = useRef()
  const startRef = useRef()
  const endRef = useRef()
  const [formError, setFormError] = useState("");
const [isLoading,setIsLoading] = useState(false)


useEffect(() => {
  if (props?.addressId) {
    getEventDetails(props?.addressId);
  }
}, [props?.addressId]);

useEffect(() => {
  if (nameRef.current) {
    nameRef.current.focus(); 
  }
}, []);

const getEventDetails = async (id) => {
  try {
    const res = await getSingleMasterEvent(id);

    if (res?.status === 200) {
      const eventData = {
        event_name: res.results.event_name,
        start_date: new Date(res.results.start_date),
        end_date: new Date(res.results.end_date),
        is_active: res.results.is_active,
      };
      setForm(eventData);
      setInitialForm(eventData); 
      
    }
  } catch (error) {}
};


  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.eventSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const handleSubmit = async () => {
    const validationResult = await utils?.checkFormError(
      form,
      errorSchema.eventSchema
    );
    const payload = {}

    Object.keys(form).map((item, index) => {
      if (form[item] !== initialForm[item]) {
        payload[item] = form[item];

        if(item==="start_date"){
            payload["start_date"] = utils?.formatDateDash(form[item]);
        }
        if(item==="end_date"){
            payload["end_date"] = utils?.formatDateDash(form[item]);
        }
      }

      if(item==="start_date"){
        payload["start_date"] = utils?.formatDateDash(form[item]);
    }
    if(item==="end_date"){
        payload["end_date"] = utils?.formatDateDash(form[item]);
    }


    });

    if (validationResult === true) {
        
        try {
          setIsLoading(true)
          if (props?.addressId) {
            const res = await updateMasterEvent({
              payload,
              id: props?.addressId,
            });
  
           
            if (res.status === 200) {
              setIsLoading(false)
              toast.success(res.message);
              props?.closeModal();
              props?.callBack();
            }else{
                toast.error(res.error);
              }
          } else {
            const res = await createMasterEvent(payload);
            if (res.status === 201) {
                setIsLoading(false)
                toast.success(res.message);
                props?.closeModal();
                props?.callBack();
            }else{
                toast.error(res.error);
              }
            }
            } catch (error) {
              setIsLoading(false)
            }
      }else{
        setFormError(validationResult)
      }
    
  };

  const objectBid = {
    ...props,
    onSubmit: handleSubmit,
    disabled:isLoading || props?.eventView,
    isLoadding:isLoading
  };

  const handleKey = (e,nextInputRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextInputRef) {
        nextInputRef.current.focus(); 
      } else {
        handleSubmit(e);
      }
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
      }
  };

  return (
    <>
      <CommonModal {...objectBid}>
        <div className="_plcMod_body" style={{ padding: "20px" }}>
          <div className="flxInr">
            <div className="singleDiv">
              <CommonInput
                type="text"
                label="Event Name"
                placeholder="Enter Event Name"
                name="event_name"
                value={form?.event_name}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name,value);
                }}
                focus={!!formError?.event_name}
                error={formError.event_name}
                ref={nameRef}
                onKeyDown={(e) => handleKey(e, null)}
                 disabled={props?.eventView}
              />
            </div>
            <div className="singleDiv">
            <CommonDate
                  name="date"
                  label="Start Date"
                  startDate={form?.start_date}
                  setStartDate={(data) => {
                    handleOnChange("start_date", data);
                  }}
                  error={formError?.start_date}
                  focus={!!formError?.start_date}
              
                />
            </div>
            <div className="singleDiv">
            <CommonDate
                  name="date"
                  label="End Date"
                  startDate={form?.end_date}
                  setStartDate={(data) => {
                    handleOnChange("end_date", data);
                  }}
                  error={formError?.end_date}
                  focus={!!formError?.end_date}
                 
                />
            </div>
            <div className="singleDiv">
              
            </div>
          </div>
        </div>
      </CommonModal>
      <div className="offBack_drop"></div>
    </>
  );
};

export default AddEventModal;
