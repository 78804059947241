import { combineReducers } from "@reduxjs/toolkit";
import { authSlice } from "features";

import { masterSlice } from "features/masterService";

const rootReducer = combineReducers({
  auth: authSlice,
  master:masterSlice
  // Add additional reducers here
});

export default rootReducer;
