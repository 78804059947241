import { constant } from "constants";

Array.prototype.mapWithKey = function (callback) {
  let newArray = [];
  for (let index = 0; index < this.length; index++) {
    let counter = callback(
      this[index],
      JSON.stringify(this[index]),
      index,
      this
    );
    newArray.push(counter);
  }
  return newArray;
};

export const utils = {
  isEqualObject: (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2),

  isObjectValueEmpty: (obj) =>
    Object.values(obj).every(
      (item) => item !== "" || item !== "undefined" || item !== undefined
    )
      ? true
      : false,

  isObjectKeyEmpty: (obj) => (Object.keys(obj).length ? false : true),
  isObjectKeyExist: (obj, key) => Object.keys(obj).includes(key),

  checkFormError: async (inputValue, schema) => {
    try {
      const validationResult = await schema.validate(inputValue, {
        abortEarly: false,
      });
      return !!validationResult;
    } catch (error) {
      let obj = {};
      error?.inner?.forEach((vr) => {
        obj[vr.path] = vr.errors[0];
      });
      return obj;
    }
  },
  dynamicCheckFormError: async (formValue, schema, context) => {
    try {
      await schema.validate(formValue, { abortEarly: false, context: context });
      return true;
    } catch (err) {
      const errors = {};
      err?.inner?.forEach((error) => {
        errors[error.path] = error.message;
      });
      return errors;
    }
  },
  getResposeError: (resError) => {
    let error = "";
    if (typeof resError === "string") {
      error = resError;
    } else {
      for (const key in resError) {
        if (Object.hasOwnProperty.call(resError, key)) {
          error =
            typeof resError[key] === "string"
              ? resError[key]
              : resError[key][0];
        }
      }
    }
    return error;
  },

  getResponseAllErrors: (resError) => {
    let error = {};
    for (const key in resError) {
      if (Object.hasOwnProperty.call(resError, key)) {
        error[key] = resError[key][0];
      }
    }
    return error;
  },

  queryString: (params) =>
    Object.keys(params)
      .map((key) => key + "=" + params[key])
      .join("&"),

  spliceString: (
    text = "", // string
    start = 0, // index
    end = 0, // index
    join = "", // character
    from = "", // character
    to = 0 //lenght or count
  ) => {
    if (from !== "") {
      let ind = text.indexOf(".");
      return (
        text.slice(start, end > ind ? ind : end) +
        join +
        text.slice(ind, text.length)
      );
    } else {
      return text?.slice(start, end);
    }
  },

  firstLetterCapital: (word = "") =>
    word?.length > 1
      ? word?.charAt(0).toUpperCase() + word?.slice(1)
      : word?.toUpperCase(),

  capitalFirstLetter: (word = "") =>
    word?.length > 1 ? word?.charAt(0).toUpperCase() : "NA",

  convertParseFloat: (num) =>
    num != 0 ? parseFloat(num).toFixed(2).replace(/\.00$/, "") : num,

  lengthBeforeDecimal: (number) => {
    const numberStr = number.toString();
    const decimalIndex = numberStr.indexOf(".");
    if (decimalIndex === -1) {
      return numberStr.length;
    } else {
      return decimalIndex;
    }
  },

  formatDate: (date = "") => {
    if (!date) {
      return "";
    }
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    return `${year}/${month}/${day}`;
  },

  formatDateDash: (date = "") => {
    if (!date) {
      return "";
    }
    const newDate = new Date(date);
    const year = newDate.getFullYear();
    const month = String(newDate.getMonth() + 1).padStart(2, "0");
    const day = String(newDate.getDate()).padStart(2, "0");

    return `${day}-${month}-${year}`;
  },
  getDayOfDate: (date, count, act = "add") => {
    const resultDate = new Date(date);
    switch (act) {
      case "sub":
        resultDate.setDate(resultDate.getDate() - count);
        break;
      case "add":
        resultDate.setDate(resultDate.getDate() + count);
        break;
    }
    return utils.formatDate(resultDate);
  },

  stringMonthDate: (val) => {
    const value1 = utils?.formatDate(val).split("/")[1];
    const value2 = utils?.formatDate(val).split("/")[2];

    const value3 = constant?.monthList?.filter((el) =>
      +el?.value === +value1 ? el : ""
    )[0];

    const value4 = utils?.spliceString(value3?.label, 0, 3);

    return `${value4} ${value2}`;
  },

  formatAMPM: (data) => {
    var date = new Date(data);
    var hours = date?.getHours();
    var minutes = date?.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  },

  dateInStringForm: (val) => {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    const d = new Date(val);
    const dayName = days[d.getDay()];
    const value0 = utils?.formatDate(val).split("/")[0];
    const value1 = utils?.formatDate(val).split("/")[1];
    const value2 = utils?.formatDate(val).split("/")[2];

    const value3 = constant?.monthList?.filter((el) =>
      +el?.value === +value1 ? el : ""
    )[0];

    const value4 = value3?.label;

    return `On ${dayName} ${value2} ${value4} ${value0}`;
  },

  is6ValidNumber: (value) => /^[0-9]{1,6}$/.test(value),

  convertQueryParamsObject: (param, length) => {
    let obj = {};
    param.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  },

  isValidURL: (str) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?" + // port
        "(\\/[-a-z\\d%_.~+]*)*" + // path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  },

  isImageLink: (url) => {
    const imageRegex = /image|\.jpeg|\.jpg|\.gif|\.png|\.svg(\?.*)?$/;
    return imageRegex.test(url);
  },

  isVideoLink: (url) => {
    const directLinkPattern = /\.(mp4|webm|ogg|mov)$/i;
    const youTubePattern = /youtube\.com|youtu\.be/i;
    const vimeoPattern = /vimeo\.com/i;
    const dailymotionPattern = /dailymotion\.com/i;

    return (
      directLinkPattern.test(url) ||
      youTubePattern.test(url) ||
      vimeoPattern.test(url) ||
      dailymotionPattern.test(url)
    );
  },

  removeHashtag: (str) => {
    if (!str) return "";
    return str.startsWith("#") ? str.substring(1) : str;
  },
  isUrl: (text) => {
    const urlRegex = /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim;
    const urlRegex2 = /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim;

    if (text.match(urlRegex)) {
      return true;
    } else if (text.match(urlRegex2)) {
      return true;
    }

    return false;
  },
  convertURLsToAnchors: (text) => {
    // Regular expression to match URLs
    const urlRegex = /(http|https):\/\/(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim;
    const urlRegex2 = /(\S+)\.([a-z]{2,}?)(.*?)( |\,|$|\.)/gim;

    if (text.match(urlRegex)) {
      return text.replace(urlRegex, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    } else if (text.match(urlRegex2)) {
      return text.replace(urlRegex2, (url) => {
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      });
    }

    return text;
  },
  getTodayDate: () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  },
  stripHtmlTags: (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  },
  // const div = document.createElement('div');
  // div.innerHTML = html;

  //   // Get the first <p> element
  //   const firstP = div.querySelector('p');

  //   // Return the text content of the first <p> element, or the full text content if no <p> elements
  //   return firstP ? firstP.textContent || firstP.innerText : div.textContent || div.innerText || '';
  // }

  //     const div = document.createElement('div');
  //   div.innerHTML = html;

  //   // Get all <p> elements
  //   const pElements = div.querySelectorAll('p');

  //   // Extract text content from each <p> element and join with a separator
  //   const textContents = Array.from(pElements).map(node => node.textContent || node.innerText || '');
  //   return textContents.join(' - ');
  // }
  getDateTwoDaysAfterDate: () => {
    const today = new Date();
    const twoDaysLater = new Date(today);
    twoDaysLater.setDate(today.getDate() + 2);

    const year = twoDaysLater.getFullYear();
    const month = String(twoDaysLater.getMonth() + 1).padStart(2, "0");
    const day = String(twoDaysLater.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  },
  localDateWithTime: (date) => {
    const now = new Date(date);
    const formattedDate = now.toDateString();
    const formattedTime = now.toLocaleString("en-US", { timeStyle: "short" });
    return `${formattedDate} ${formattedTime}`;
  },

  formatDateMonth: (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  },

  formatTime: (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  },
  formateDateCalander: (date) => {
    if (date) {
      const today = new Date(date);
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${day}-${month}-${year}`;
    } else {
      return new Date();
    }
  },
  getDateFifteenDaysAfterDate: (date) => {
    const today = new Date(date);
    const fifteenDaysLater = new Date(today);
    fifteenDaysLater.setDate(today.getDate() + 15);

    const year = fifteenDaysLater.getFullYear();
    const month = String(fifteenDaysLater.getMonth() + 1).padStart(2, "0");
    const day = String(fifteenDaysLater.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  },
};
