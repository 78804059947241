
import React, {  useEffect, useState } from "react";
import {  NavLink, useNavigate } from "react-router-dom";
import { Images } from "constants";
import { MdOutlineDashboard } from "react-icons/md";
import { FaCalendarAlt, FaDoorOpen, FaBuilding ,FaRegUser,FaRegListAlt,FaBed} from "react-icons/fa"; 
import { TbReport } from "react-icons/tb";
import { IoKeyOutline } from "react-icons/io5";



const Sidebar = () => {
  
  const list = [
    {
      label: "Dashboard",
      link: "/dashboard",
      childs: [],
      icon:<MdOutlineDashboard/>
    },
    {
      label: "Guest",
      link: "/guest",
      childs: [],
      icon: <FaRegUser />,
    },
    {
      label: "Master",
      link: "/master",
      icon:<FaRegListAlt/>,
      childs: [
        {
          
          label: "Event",
          link: "master/event",
          icon: <FaCalendarAlt />,

        },
        {
          
          label: "Rooms",
          link: "master/room",
          icon: <FaCalendarAlt />,
        },
        {
          
          label: "Floor",
          link: "master/floor",
          icon: <FaCalendarAlt />,
        },
      ],
    },
    {
      label: "Report",
      link: "/hotal_report",
      childs: [],
      icon:<TbReport/>
    },
    {
      label: "Room Booking",
      link: "/booking/room",
      childs: [],
      icon:<FaBed/>
    },
    {
      label: "Key Request",
      link: "/keyrequest",
      childs: [],
      icon:<IoKeyOutline/>
    },
    
  ];

  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(
    () => JSON.parse(localStorage.getItem("activeIndex")) || 0
  );
  const [openAccordionIndex, setOpenAccordionIndex] = useState(
    () => JSON.parse(localStorage.getItem("openAccordionIndex")) || null
  );

  useEffect(() => {
    localStorage.setItem("activeIndex", JSON.stringify(activeIndex));
  }, [activeIndex]);

  useEffect(() => {
    localStorage.setItem("openAccordionIndex", JSON.stringify(openAccordionIndex));
  }, [openAccordionIndex]);


  const handleAccordionToggle = (outerIndex) => {

    setOpenAccordionIndex((prevIndex) =>{
     return prevIndex === outerIndex ? null : outerIndex}
    );
  };

  const handleNavLinkClick = (link, event, index) => {
    event.preventDefault();
      setActiveIndex(index);
      navigate(link);
    
  };

  return (
    //  SideBar Start
    <aside className="sideBar">
      <div className="_logoInx">
        <img src={Images.LOGO} alt="" />
     
      </div>

      <div className="menuBx_otr">
        <ul className="_simpList accordion" id="accordionExample">
          {list &&
            list?.map((item, outerIndex) => {
              if (!item?.childs?.length) {
                return (
                 
                    <li className="_clk_li" key={`sidemenubar_${outerIndex}`}>
                      <NavLink
                        to={item?.link || "#"}
                        className={({ isActive }) =>
                          `_clk_a ${isActive ? "active" : ""}`
                        }
                        onClick={(event) =>{
                         handleAccordionToggle(null)
                          handleNavLinkClick(item?.link, event, outerIndex)}
                        }
                      >
                        {/* <i className={item?.icon}></i> */}
                        {item.icon && <i>{item.icon}</i>}
                        <span>{item?.label}</span>
                      </NavLink>
                    </li>
                  
                );
              } else {
                return (
                  
                    <li
                      className={`_clk_li accordion-item ${
                        activeIndex === outerIndex ? "anvAccordian_active" : ""
                      }`}
                      key={`sidemenubar_${outerIndex}`}
                    >
                      <button
                        className="accordion-button collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={`#${item.label}${outerIndex}`}
                        aria-expanded={openAccordionIndex === outerIndex}
                        aria-controls={`${item.label}${outerIndex}`}
                        onClick={() => handleAccordionToggle(outerIndex)}
                      >
                        {item.icon && <i>{item.icon}</i>}
                        <span>{item?.label}</span>

                        <div className="_clk_chevron">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="5"
                            height="8"
                            viewBox="0 0 5 8"
                            fill="none"
                          >
                            <path
                              d="M4.36632 3.63368L0.968818 0.236182C0.853799 0.13001 0.721084 0.0769238 0.570674 0.0769238C0.420264 0.0769238 0.287549 0.13001 0.172529 0.236182C0.0575098 0.351201 0 0.483916 0 0.634326C0 0.784736 0.0575098 0.917451 0.172529 1.03247L3.17189 4.03183L0.172529 7.03118C0.0575098 7.1462 0 7.27892 0 7.42933C0 7.57974 0.0575098 7.71245 0.172529 7.82747C0.234463 7.8894 0.294185 7.93364 0.351694 7.96019C0.409204 7.98673 0.482197 8 0.570674 8C0.65915 8 0.732144 7.98673 0.789653 7.96019C0.847163 7.93364 0.906885 7.8894 0.968818 7.82747L4.36632 4.42997C4.48134 4.31495 4.53885 4.18224 4.53885 4.03183C4.53885 3.88142 4.48134 3.7487 4.36632 3.63368Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </button>

                      <div
                       id={`${item.label}${outerIndex}`}
                       className={`accordion-collapse collapse ${
                         openAccordionIndex === outerIndex ? "show" : ""
                       }`}
                       aria-labelledby="headingOne"
                       data-bs-parent="#accordionExample"
                      >
                        <h5 className="_clsk_head">{item?.label}</h5>
                        <div className="accordion-body">
                          <ul className="submenuUl">
                            {item?.childs &&
                              item?.childs.map(
                                (child, childIndex) =>
                                  
                                    <li key={`childmenu_${childIndex}`}>
                                      <NavLink
                                        to={child?.link || "#"}
                                        className={({ isActive }) =>
                                          `${isActive ? "active" : ""}`
                                        }
                                        onClick={(event) =>{
                                          handleNavLinkClick(
                                            child?.link,
                                            event,
                                            outerIndex
                                          )
                                        }
                                        }
                                      >
                                        {/* <i className={child?.icon}></i> */}
                                        {/* {child.icon && <i>{child.icon}</i>} */}
                                        <span>{child.label}</span>
                                      </NavLink>
                                    </li>
                                  
                              )}
                          </ul>
                        </div>
                      </div>
                    </li>
                  
                );
              }
            })}
        </ul>
      </div>
    </aside>
  );
};

export default Sidebar;
