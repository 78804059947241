import { createMasterEvent, createMasterRoom, getSingleMasterEvent, getSingleMasterRoom, handleKeyDown, updateMasterEvent, updateMasterRoom } from "_services";
import {
  CommonDate,
  CommonDropdown,
  CommonInput,
  CommonModal,
  CustomSelect,
} from "components";
import { createEvent } from "features/masterService";
import { errorSchema, utils } from "helper";
import React, { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../assets/css/modal.css";
import { createGuest, getSingleGuest, updateGuest } from "_services/guestServices";

const AddGuestModal = (props) => {

const userTypeList = [
    {label:"ADMIN",value:1},
    {label:"GUEST",value:2},
    {label:"STAFF",value:3},
]

const genderList = [
    {label:"MALE",value:1},
    {label:"FEMALE",value:2}
]

  const [form, setForm] = useState({
    name: "",
    email: '',
    mobile_number: "",
    user_type: "",
    gender:"",
    state:"",
    city:"",
    address:"",
  });
  const [initialForm, setInitialForm] = useState({
    name: "",
    email: '',
    mobile_number: "",
    user_type: "",
    gender:"",
    state:"",
    city:"",
    address:"",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nameRef = useRef()
  const emailRef = useRef()
  const mobileRef = useRef()
  const userRef = useRef()
  const genderRef = useRef()
  const stateRef = useRef()
  const cityRef = useRef()
  const addressRef = useRef()
  const dropdownRef = useRef(null);
  const [formError, setFormError] = useState("");
const [isLoading,setIsLoading] = useState(false)


useEffect(() => {
  if (props?.addressId) {
    getEventDetails(props?.addressId);
  }
}, [props?.addressId]);

useEffect(() => {
  if (nameRef.current) {
    nameRef.current.focus(); 
  }
}, []);

const getEventDetails = async (id) => {
  try {
    const res = await getSingleGuest(id);
    if (res?.status === 200) {
      const eventData = {
        name:res?.results?.name,
        email: res?.results?.email,
        mobile_number: res?.results?.mobile_number,
        user_type: res?.results?.user_type,
        gender:res?.results?.gender,
        state:res?.results?.state,
        city:res?.results?.city,
        address:res?.results?.address,
      };
      setForm(eventData);
      setInitialForm(eventData); 
      
    }
  } catch (error) {}
};


  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.guestSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const handleSubmit = async () => {
    const validationResult = await utils?.checkFormError(
      form,
      errorSchema.guestSchema
    );
    const payload = {}

    Object.keys(form).map((item, index) => {
      if (form[item] !== initialForm[item]) {
        payload[item] = form[item];        
      }
    });

    if (validationResult === true) {
        
        try {
          setIsLoading(true)
          if (props?.addressId) {
            const res = await updateGuest({
              payload,
              id: props?.addressId,
            });
  
           
            if (res.status === 200) {
              setIsLoading(false)
              toast.success(res.message);
              props?.closeModal();
              props?.callBack();
            }else{
                toast.error(res.error);
              }
          } else {
            const res = await createGuest(payload);
            if (res.status === 201) {
                setIsLoading(false)
                toast.success(res.message);
                props?.closeModal();
                props?.callBack();
            }else{
                toast.error(res.error);
              }
            }
            } catch (error) {
              setIsLoading(false)
            }
      }else{
        setFormError(validationResult)
      }
    
  };

  const objectBid = {
    ...props,
    onSubmit: handleSubmit,
    disabled:isLoading || props?.eventView,
    isLoadding:isLoading
  };

  const handleKey = (e,nextInputRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextInputRef) {
        nextInputRef.current.focus(); 
      } else {
        handleSubmit(e);
      }
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
        e.preventDefault();
      }
  };

  return (
    <>
      <CommonModal {...objectBid}>
        <div className="_plcMod_body" style={{ padding: "20px" }}>
          <div className="flxInr">
            <div className="singleDiv">
              <CommonInput
                type="text"
                label="Name"
                placeholder="Enter Name"
                name="name"
                value={form?.name}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name,value);
                }}
                focus={!!formError?.name}
                error={formError.name}
                ref={nameRef}
                onKeyDown={(e) => handleKey(e, emailRef)}
                 disabled={props?.eventView}
              />
            </div>
            <div className="singleDiv">
            <CommonInput
                type="text"
                label="Email"
                placeholder="Enter Email Address"
                name="email"
                value={form?.email}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name,value);
                }}
                focus={!!formError?.email}
                error={formError.email}
                ref={emailRef}
                onKeyDown={(e) => handleKey(e, mobileRef)}
                 disabled={props?.eventView}
              />
            </div>
            <div className="singleDiv">
            <CommonInput
                type="text"
                label="Mobile"
                placeholder="Enter Mobile Number"
                name="mobile_number"
                value={form?.mobile_number}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name,value);
                }}
                focus={!!formError?.mobile_number}
                error={formError.mobile_number}
                ref={mobileRef}
                onKeyDown={(e) => handleKey(e, userRef)}
                 disabled={props?.eventView}
                 onKeyPress={handleKeyDown}
                 maxLength={10}
              />
            </div>
            <div className="singleDiv">
            <CustomSelect
                  label="User Type"
                  name="user_type"
                  placeholder="Select User Type"
                  onSelect={(data) => {
                    handleOnChange("user_type", data.value);
                  }}
                  value={
                   userTypeList?.find((i) => i.value == form?.user_type)
                  }
                  options={userTypeList}
                  focus={!!formError?.user_type}
                  error={formError.user_type}
                  ref={userRef}
                  onEnterSelect={() => genderRef.current.focus()}
                />
            </div>
            <div className="singleDiv">
            <CustomSelect
                  label="Gender"
                  name="gender"
                  placeholder="Select Gender "
                  onSelect={(data) => {
                    handleOnChange("gender", data.value);
                  }}
                  value={
                    genderList?.find((i) => i.value == form?.gender)
                  }
                  options={genderList}
                  focus={!!formError?.gender}
                  error={formError.gender}
                  ref={genderRef}
                  enableSearch={true}
                  onEnterSelect={() => stateRef.current.focus()}
                />
            </div>
            <div className="singleDiv">
            <CommonInput
                type="text"
                label="State"
                placeholder="Enter State"
                name="state"
                value={form?.state}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name,value);
                }}
                focus={!!formError?.state}
                error={formError.state}
                ref={stateRef}
                onKeyDown={(e) => handleKey(e, cityRef)}
                 disabled={props?.eventView}
              />
            </div>
            <div className="singleDiv">
            <CommonInput
                type="text"
                label="City"
                placeholder="Enter City"
                name="city"
                value={form?.city}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name,value);
                }}
                focus={!!formError?.city}
                error={formError.city}
                ref={cityRef}
                onKeyDown={(e) => handleKey(e, addressRef)}
                 disabled={props?.eventView}
              />
            </div>
            <div className="singleDiv">
            <CommonInput
                type="text"
                label="Address"
                placeholder="Enter Address"
                name="address"
                value={form?.address}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name,value);
                }}
                focus={!!formError?.address}
                error={formError.address}
                ref={addressRef}
                onKeyDown={(e) => handleKey(e, null)}
                 disabled={props?.eventView}
              />
            </div>
          </div>
        </div>
      </CommonModal>
      <div className="offBack_drop"></div>
    </>
  );
};

export default AddGuestModal;
