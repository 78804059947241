import axios from "axios";
import AppConfig from "./app-config";
import toast from "react-hot-toast";

const axiosInstance = axios.create({
  baseURL: AppConfig.api_baseurl,
  timeout: 10000, // Set a timeout limit for requests
  headers: AppConfig.headers,
});


// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Add any custom logic before the request is sent
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response interceptor
// axiosInstance.interceptors.response.use(
//   (response) => {
//     // Any custom logic for handling successful responses
//     return response;
//   },
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       // Example: Redirect to login if unauthorized
//       localStorage.clear();
//       window.location.href = "/";
//       window.location.reload();
//     }
//     return Promise.reject(error);
//   }
// );

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.clear();
        window.location.href = "/";
        window.location.reload();
      } else if (data && data.error) {
        // Check if `data.error` has nested objects
        const errorContent = typeof data.error === "object" ? data.error : { general: [data.error.toString()] };

        Object.values(errorContent).forEach((nestedError) => {
          // Handle each nested error (e.g., `guest`)
          if (typeof nestedError === "object") {
            Object.values(nestedError).forEach((errorMessages) => {
              if (Array.isArray(errorMessages)) {
                errorMessages.forEach((msg) => toast.error(msg));
              } else {
                toast.error(errorMessages.toString()); // Convert non-array values to string
              }
            });
          } else {
            toast.error(nestedError.toString());
          }
        });
      } else {
        toast.error("An unexpected error occurred.");
      }
    } else {
      toast.error("Network error. Please try again.");
    }

    return Promise.reject(error);
  }
);

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => {
    
//     if (error.response) {
//       const { status, data } = error.response;
//       console.log("eeee",status)
//       if (status === 401) {
//         localStorage.clear();
//         window.location.href = "/";
//         window.location.reload();
//       } else if (data && data?.error) {
//         // Show API error in a toaster
//         Object.values(data?.error).forEach((errorMessages) => {
//           errorMessages.forEach((msg) => toast.error(msg));
//         });
//       } else {
//         toast.error("An unexpected error occurred.");
//       }
//     } else {
//       toast.error("Network error. Please try again.");
//     }

//     return Promise.reject(error);
//   }
// );


export default axiosInstance;
