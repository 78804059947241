import { API, axiosInstance } from "lib";

export const getGuest = (data,filter) => {
    return axiosInstance
    .get(data?`${data}?${filter}`:`${API.GUEST}?${filter}`, {})
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        console.error("Axios Error:", err.message);
        if (err.response) {
          console.error("Server Response:", err.response.status, err.response.data);
        }
        throw err;
      });
  };

  export const createGuest = (data = {}) => {
    return axiosInstance
      .post(API.GUEST, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };

  export const deleteGuest = (data = {}) => {
    return axiosInstance
      .delete(`${API.GUEST}/${data?.id}`, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };
  
  
  export const getSingleGuest = (data = {}) => {
    return axiosInstance
      .get(`${API.GUEST}/${data}`, data)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };

  export const getSingleGuestMobile = (filters) => {
    const queryParams = new URLSearchParams(filters).toString();
    return axiosInstance
    .get(`${API.GUEST}?${queryParams}`)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };
  
  export const updateGuest = (data = {}) => {
    return axiosInstance
      .patch(`${API.GUEST}/${data?.id}`, data.payload)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };
  
  export const changeGuestStatus = (data = {}) => {
    return axiosInstance
      .patch(`${API.GUEST}/${data?.id}`, data.payload)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        if (err) {
          console.log(err);
        }
        throw err;
      });
  };

  