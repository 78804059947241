import { useState } from "react";

const DocumentImages = ({ documentDetails }) => {
    const [isZoomed, setIsZoomed] = useState(false);
  
    const handleZoom = () => {
      setIsZoomed(true);
    };
  
    const closeZoom = () => {
      setIsZoomed(false);
    };
  
    return (
      <div>
        <img
          src={documentDetails?.document_file}
          alt="Document Thumbnail"
          onClick={handleZoom}
          style={{ cursor: 'pointer', width: '100%', height: 'auto' }}
        />
        
        {isZoomed && (
          <div
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 1000,
            }}
            onClick={closeZoom}
          >
            <img
              src={documentDetails?.document_file}
              alt="Document Zoomed"
              style={{
                transform: 'scale(3)',
                maxWidth: '100%',
                maxHeight: '100%',
                width: 'auto',
                height: 'auto',
                boxShadow: '0px 0px 15px rgba(0,0,0,0.5)',
                borderRadius: '8px',
              }}
            />
            <button
              onClick={closeZoom}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                background: 'transparent',
                border: 'none',
                color: '#fff',
                fontSize: '24px',
                cursor: 'pointer',
              }}
            >
              &times;
            </button>
          </div>
        )}
      </div>
    );
  };
  
  export default DocumentImages;