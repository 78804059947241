import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './commonDatePicker.css';
import moment from 'moment';

const CommonDate = (props) => {
    const {
        name,
        error,
        startDate,
        setStartDate,
        id = Math.random() * 100000,
        label = 'Select Date',
        disabled,
        minDate
    } = props;

    useEffect(() => {
        if (id) {
            const element = document.getElementById(id);
            const handleMouseDown = (e) => {
                if (e.detail > 1) {
                    e.preventDefault();
                }
            };
            if (element) {
                element.addEventListener('mousedown', handleMouseDown);
            }
            return () => {
                if (element) {
                    element.removeEventListener('mousedown', handleMouseDown);
                }
            };
        }
    }, [id]);

    return (
        <div className={error ? 'anv_dateTimePicker anvInputError' : 'anv_dateTimePicker'}>
            <span className='anvDate_lable' data-label={label}></span>
            <DatePicker
                id={id}
                name={name}
                selected={startDate}
                disabled={disabled}
                placeholderText='Select Date'
                onChange={(date) => setStartDate(date)}
                dateFormat="dd-MM-yyyy"  // Date format only
                popperClassName="someCustom"
                minDate={minDate}
            />
            {error && (
                <span className="input_drop_error">
                    {error}
                </span>
            )}
        </div>
    );
};

export default CommonDate;
