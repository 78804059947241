import {
  handleKeyDown,
  getEvent,
  getAllEvent,
  getAllRoom,
  createRoomBooking,
  updateRoomBooking,
  getSingleRoomBooking,
  getAllFloor,
  getRoomAvailable,
  uploadFiles,
} from "_services";
import { getSingleGuestMobile } from "_services/guestServices";
import {
  CommonDatePicker,
  CommonInput,
  CommonTextarea,
  CustomSelect,
  Heading,
} from "components";
import { BasicExample } from "components/common/CommonModal/CommonModal";
import DocumentImages from "components/common/DocumentImage";

import { errorSchema, utils } from "helper";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";

const CreateRoomBooking = () => {
  const initi = {
    event: "",
    special_requests: "",
    total_guests: "",
    mobile_number: "",
    name: "",
    email: "",
    gender: "",
    state: "",
    city: "",
    address: "",
    room: "",
    check_in: new Date(),
    check_out: "",
  };

  const genderList = [
    { label: "MALE", value: 1 },
    { label: "FEMALE", value: 2 },
  ];

  const documentypeList = [
    { label: "Aadhar Card", value: 1 },
    { label: "Pan Card", value: 2 },
    { label: "Voter ID", value: 3 },
    { label: "Driving License", value: 4 },
    { label: "Passport", value: 5 },
  ];

  const navigate = useNavigate();
  const { id, eventView } = useParams();
  const eventRef = useRef(null);
  const mobileRef = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const genderRef = useRef(null);
  const stateRef = useRef(null);
  const cityRef = useRef(null);
  const addressRef = useRef(null);
  const roomRef = useRef(null);
  const floorRef = useRef(null);
  const specialRef = useRef(null);
  const totalRef = useRef(null);
  const checkinRef = useRef(null);
  const typeRef = useRef();
  const numberRef = useRef();
  const [form, setForm] = useState(initi);
  const [initialForm, setInitialForm] = useState(initi);
  const [initialDocument, setInitialDocument] = useState({
    document_type: "",
    document_number: "",
    document_file: "",
  });
  const [formError, setFormError] = useState("");
  const [allEventList, setAllEventList] = useState([]);
  const [allRoomList, setAllRoomList] = useState([]);
  const [allFloorList, setAllFloorList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [documentDetails, setDocumentDetails] = useState({
    document_type: "",
    document_number: "",
    document_file: "",
  });
  const [guestId, setGuestId] = useState("");
  useEffect(() => {
    if (mobileRef.current) {
      mobileRef.current.focus();
    }
  }, []);

  useEffect(() => {
    getEventList();
    getFloorList();
  }, []);

  useEffect(() => {
    if (form?.event && form?.floor) {
      getRoomList(form?.event, form?.floor);
    }
  }, [form?.event, form?.floor]);

  useEffect(() => {
    if (id) {
      getRoomBookingDetails(id);
    }
  }, [id]);

  useEffect(() => {
    if (form?.mobile_number && form.mobile_number.length === 10) {
      getGuestDetails(form?.mobile_number);
    }
  }, [form?.mobile_number]);

  const getRoomBookingDetails = async (id) => {
    try {
      const res = await getSingleRoomBooking(id);
      console.log(res);
      if (res?.status === 200) {
        const eventData = {
          event: res?.results?.event,
          special_requests: res?.results?.special_requests,
          total_guests: res?.results?.total_guests,
          name: res?.results?.guest?.name,
          email: res?.results?.guest?.email,
          mobile_number: res?.results?.guest?.mobile_number,
          gender: res?.results?.guest?.gender,
          state: res?.results?.guest?.state,
          city: res?.results?.guest?.city,
          address: res?.results?.guest?.address,
          room: res?.results?.room,
          floor: res?.results?.floor,
          check_in: res?.results?.check_in,
          check_out: res?.results?.check_out,
        };
        setForm((prevForm) => ({ ...prevForm, ...eventData }));
        setInitialForm((prevForm) => ({ ...prevForm, ...eventData }));
        setDocumentDetails({
          ...documentDetails,
          document_file: res?.results?.guest?.document_file,
          document_number: res?.results?.guest?.document_number,
          document_type: res?.results?.guest?.document_type,
        });
        setInitialDocument({
          ...initialDocument,
          document_file: res?.results?.guest?.document_file,
          document_number: res?.results?.guest?.document_number,
          document_type: res?.results?.guest?.document_type,
        });
      }
    } catch (error) {
      console.error("Failed to fetch booking details:", error);
    }
  };

  const getEventList = async () => {
    try {
      const res = await getAllEvent({ limit: "all" });

      if (res?.status === 200) {
        const filterEvent = res.results?.find((i) => i?.is_active === true);
        setAllEventList([
          {
            ...filterEvent,
            label: filterEvent?.event_name,
            value: filterEvent?.value,
          },
        ]);
        if(!id){
          setForm({ ...form, event: filterEvent?.id });
        }
        
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const getRoomList = async (event, floor) => {
    try {
      const res = await getRoomAvailable({
        event_id: event,
        floor_name: floor,
      });

      if (res?.status === 200) {
        const newList = res.results?.map((i) => {
          return {
            ...i,
            label: String(i?.room_number),
            value: i?.id,
          };
        });

        setAllRoomList(newList);
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const getFloorList = async () => {
    try {
      const res = await getAllFloor({ limit: "all" });

      if (res?.status === 200) {
        // const filterEvent = res.results?.find((i)=>i?.is_active===true);
        const newList = res.results?.map((i) => {
          return {
            ...i,
            label: i?.name,
            value: i?.id,
          };
        });
        
        setAllFloorList(newList);
      } else {
        toast.error(res.error);
      }
    } catch (error) {
      toast.error(error);
    } finally {
    }
  };

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
        ...(name === "floor" && { room: "" }),
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.addRoomBookingSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const getGuestDetails = async (mobile) => {
    try {
      const res = await getSingleGuestMobile({ mobile_number: mobile });
      if (res?.status === 200) {
        setForm({
          ...form,
          name: res?.results.length ? res?.results[0]?.name : "",
          email: res?.results.length ? res?.results[0]?.email : "",
          gender: res?.results.length ? res?.results[0]?.gender : "",
          state: res?.results.length ? res?.results[0]?.state : "",
          city: res?.results.length ? res?.results[0]?.city : "",
          address: res?.results.length ? res?.results[0]?.address : "",
        });

        setGuestId(res?.results[0]?.id);
      }
    } catch (error) {}
  };

  const callBackDataMap = async (data) => {};
 

  const clearAll = () => {
    setForm({
      ...form,
      event: "",
      special_requests: "",
      total_guests: "",
      mobile_number: "",
      name: "",
      email: "",
      gender: "",
      state: "",
      city: "",
      address: "",
      room: "",
      check_in: new Date(),
      check_out: "",
    });
    setInitialForm({
      ...form,
      event: "",
      special_requests: "",
      total_guests: "",
      mobile_number: "",
      name: "",
      email: "",
      gender: "",
      state: "",
      city: "",
      address: "",
      room: "",
      check_in: new Date(),
      check_out: "",
    });
  };

  const convertToISOFormat = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString();
  };


  const handleSubmit = async () => {
    try {
      // Validate form and return if errors are present
      const validationResult = await utils?.checkFormError(
        form,
        errorSchema.addRoomBookingSchema
      );
  
      if (validationResult !== true) {
        setFormError(validationResult);
        return;
      }
  
      const changedPayload = buildChangedPayload();
      const documentFormData = buildDocumentFormData();
  
      if (Object.keys(changedPayload).length === 0 && !documentFormData) {
        console.log("No changes detected.");
        return;
      }
  
      setIsLoading(true);
  
      // Submit booking update or creation
      if(Object.keys(changedPayload).length !== 0){
        const bookingResponse = id 
        ? await updateRoomBooking({ payload: changedPayload, id })
        : await createRoomBooking(changedPayload);
  
      handleBookingResponse(bookingResponse);
      }
      
  
      // Submit document details if they changed
      if (documentFormData) {
        const docResponse = await uploadFiles({
          payload: documentFormData,
          id: guestId,
        });
        handleDocumentResponse(docResponse);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Submission error:", error);
    }
  };
  
  // Helper function to build changed payload for booking details
  const buildChangedPayload = () => {
    const payload = {};
    if (!id || form?.check_in !== initialForm?.check_in) {
      payload.check_in = convertToISOFormat(form?.check_in);
    }
    if (id && form?.check_out !== initialForm?.check_out) {
      payload.check_out = convertToISOFormat(form?.check_out);
    }
  
    const fieldsToCheck = ['event', 'room', 'floor', 'total_guests', 'special_requests'];
    fieldsToCheck.forEach(field => {
      if (form[field] !== initialForm[field]) {
        payload[field] = form[field];
      }
    });
  
    const guestChanges = buildGuestChanges();
    if (Object.keys(guestChanges).length > 0) {
      payload.guest = guestChanges;
    }
  
    return payload;
  };
  
  // Helper function to track guest-specific changes
  const buildGuestChanges = () => {
    const guestFields = ['name', 'email', 'mobile_number', 'gender', 'state', 'city', 'address'];
    const guestChanges = {};
  
    guestFields.forEach(field => {
      if (form[field] !== initialForm[field]) {
        guestChanges[field] = form[field];
      }
    });
  
    return guestChanges;
  };
  
  // Helper function to build FormData for document details
  const buildDocumentFormData = () => {
    const formData = new FormData();
    const docFields = ['document_number', 'document_type'];
  
    docFields.forEach(field => {
      if (documentDetails[field] !== initialDocument[field]) {
        formData.append(field, documentDetails[field]);
      }
    });
  
    return formData.has("document_number") || formData.has("document_type")
      ? formData
      : null;
  };
  
  // Response handler for booking API
  const handleBookingResponse = (response) => {
    setIsLoading(false);
    if (response?.status === 200 || response?.status === 201) {
      toast.success(response.message);
      navigate("/booking/room");
    } else {
      toast.error(response?.error || "Failed to save booking.");
    }
  };
  
  // Response handler for document upload API
  const handleDocumentResponse = (response) => {
    setIsLoading(false);
    if (response?.status === 200) {
      toast.success(response.message);
    } else {
      toast.error(response?.error || "Failed to upload document.");
    }
  };
  

  const handleKey = (e, nextInputRef) => {
    if (e.key === "Enter") {
      console.log("nextInputRef0", nextInputRef);
      e.preventDefault();
      if (nextInputRef && nextInputRef.current) {
        nextInputRef.current.focus();
      } else {
        handleSubmit(e);
      }
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  return (
    <div>
      <Heading title={"Add Room Booking"} />
      <div className="_addContainer bg-[#FBF9FB]">
        <div className="_midCont_body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-12 col-xl-10">
                <div className="_crtDisp_listBx">
                  <div className="">
                    <h4 className="_disPatch_heads">Event Details</h4>
                    <div className="_fleX_inds">
                      <div className="_divForth">
                        <div className="singleDiv">
                          <CustomSelect
                            placeholder="Select Event"
                            label="Event"
                            options={allEventList}
                            onSelect={(data) => {
                              handleOnChange("event", data.value);
                            }}
                            focus={
                              !!(
                                typeof formError === "object" &&
                                formError?.event
                              )
                            }
                            error={
                              !!(typeof formError === "object")
                                ? formError?.event
                                : ""
                            }
                            value={allEventList?.find(
                              (i) => i?.id === form?.event
                            )}
                            disabled={true}
                            ref={eventRef}
                            enableSearch={true}
                            onEnterSelect={() => mobileRef.current.focus()}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h4 className="_disPatch_heads">Guest Details</h4>
                    <div className="_fleX_inds">
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          placeholder="Enter Mobile Number"
                          label="Mobile Number"
                          name="mobile_number"
                          value={form?.mobile_number}
                          focus={
                            !!(
                              typeof formError === "object" &&
                              formError?.mobile_number
                            )
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.mobile_number
                              : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          maxLength={10}
                          ref={mobileRef}
                          onKeyDown={(e) => handleKey(e, nameRef)}
                          disabled={eventView}
                          onKeyPress={handleKeyDown}
                        />
                      </div>
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          placeholder="Enter Name"
                          label="Name"
                          name="name"
                          value={form?.name}
                          focus={
                            !!(typeof formError === "object" && formError?.name)
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.name
                              : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          ref={nameRef}
                          onKeyDown={(e) => handleKey(e, emailRef)}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          placeholder="Enter Email"
                          label="Email"
                          name="email"
                          value={form?.email}
                          focus={
                            !!(
                              typeof formError === "object" && formError?.email
                            )
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.email
                              : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          ref={emailRef}
                          onKeyDown={(e) => handleKey(e, genderRef)}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divForth"></div>
                      <div className="_divForth">
                        <CustomSelect
                          placeholder="Select Gender"
                          label="Gender"
                          options={genderList}
                          onSelect={(data) => {
                            handleOnChange("gender", data.value);
                          }}
                          focus={
                            !!(
                              typeof formError === "object" && formError?.gender
                            )
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.gender
                              : ""
                          }
                          value={
                            form?.gender
                              ? genderList?.find(
                                  (i) => i?.value === form?.gender
                                )
                              : { label: "", value: "" }
                          }
                          ref={genderRef}
                          enableSearch={true}
                          onEnterSelect={() => stateRef.current.focus()}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          placeholder="State"
                          label="State"
                          name="state"
                          maxLength={6}
                          value={form.state}
                          focus={
                            !!(
                              typeof formError === "object" && formError?.state
                            )
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.state
                              : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          ref={stateRef}
                          onKeyDown={(e) => handleKey(e, cityRef)}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          placeholder="Enter City"
                          label="City"
                          name="city"
                          value={form.city}
                          focus={
                            !!(typeof formError === "object" && formError?.city)
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.city
                              : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          ref={cityRef}
                          onKeyDown={(e) => handleKey(e, addressRef)}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divForth"></div>
                      <div className="_divFiv">
                        <CommonTextarea
                          type="textarea"
                          label="Address"
                          name="address"
                          value={form.address}
                          focus={
                            !!(
                              typeof formError === "object" &&
                              formError?.address
                            )
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.address
                              : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          ref={addressRef}
                          onKeyDown={(e) => handleKey(e, floorRef)}
                          disabled={eventView}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h4 className="_disPatch_heads">Room Details</h4>
                    <div className="_fleX_inds">
                      <div className="_divForth">
                        <CustomSelect
                          placeholder="Select Floor"
                          label="Floor"
                          options={allFloorList}
                          onSelect={(data) => {
                            handleOnChange("floor", data.value);
                          }}
                          focus={
                            !!(
                              typeof formError === "object" && formError?.floor
                            )
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.floor
                              : ""
                          }
                          value={
                            form?.floor
                              ? allFloorList?.find(
                                  (i) => i?.value === form?.floor
                                )
                              : { label: "", value: "" }
                          }
                          ref={floorRef}
                          enableSearch={true}
                          onEnterSelect={() => roomRef.current.focus()}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divForth">
                        <CustomSelect
                          placeholder="Select Room"
                          label="Room"
                          options={allRoomList}
                          onSelect={(data) => {
                            handleOnChange("room", data.value);
                          }}
                          focus={
                            !!(typeof formError === "object" && formError?.room)
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.room
                              : ""
                          }
                          value={
                            form?.room
                              ? allRoomList?.find(
                                  (i) => i?.value === form?.room
                                )
                              : { label: "", value: "" }
                          }
                          ref={roomRef}
                          enableSearch={true}
                          onEnterSelect={() => specialRef.current.focus()}
                          disabled={eventView || !form?.floor}
                        />
                      </div>

                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          placeholder=""
                          label="Special Request"
                          name="special_requests"
                          value={form.special_requests}
                          focus={
                            !!(
                              typeof formError === "object" &&
                              formError?.special_requests
                            )
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.special_requests
                              : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          ref={specialRef}
                          onKeyDown={(e) => handleKey(e, totalRef)}
                          disabled={eventView}
                        />
                      </div>
                      <div className="_divForth"></div>
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          placeholder="Total Guest"
                          label="Total Guest"
                          name="total_guests"
                          value={form.total_guests}
                          focus={
                            !!(
                              typeof formError === "object" &&
                              formError?.total_guests
                            )
                          }
                          error={
                            !!(typeof formError === "object")
                              ? formError?.total_guests
                              : ""
                          }
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                          ref={totalRef}
                          onKeyDown={(e) => handleKey(e, null)}
                          onKeyPress={handleKeyDown}
                          disabled={eventView}
                        />
                      </div>

                      {id && (
                        <div className="_divForth">
                          <CommonDatePicker
                            name="date"
                            label="Check In"
                            startDate={form?.check_in}
                            setStartDate={(data) => {
                              handleOnChange("check_in", data);
                            }}
                            error={formError?.check_in}
                            focus={!!formError?.check_in}
                            disabled={true}
                            ref={checkinRef}
                          />
                        </div>
                      )}
                      {id && (
                        <>
                          {!form.check_out ? (
                            <div
                              className="_anvSwitcherNew form-check form-switch"
                              style={{ width: "150px" }}
                            >
                              <div className="_listbtn">
                                <button
                                  className="_addComm_btn _builtyPrintBtn"
                                  onClick={() =>
                                    setForm((pre) => ({
                                      ...pre,
                                      check_out: new Date(),
                                    }))
                                  }
                                >
                                  Check-out
                                </button>
                              </div>
                            </div>
                          ) : (
                            <div className="_divForth">
                              <CommonDatePicker
                                name="date"
                                label="Check Out"
                                startDate={form.check_out}
                                setStartDate={(data) => {
                                  handleOnChange("check_out", data);
                                }}
                                error={formError?.check_out}
                                focus={!!formError?.check_out}
                                onEnterKey={handleSubmit}
                                disabled={
                                  id
                                    ? !initialForm?.check_out
                                      ? false
                                      : true
                                    : false
                                }
                              />
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {documentDetails?.document_file && (
                    <div className="">
                      <h4 className="_disPatch_heads">Document</h4>
                      <div className="_fleX_inds">
                        <div className="_divForth">
                        <DocumentImages documentDetails={documentDetails} />
                        </div>
                        
                        
                        <div className="_divForth">
                          <CustomSelect
                            placeholder="Select Document Type"
                            label="Document Type"
                            options={documentypeList}
                            onSelect={(data) => {
                              setDocumentDetails({
                                ...documentDetails,
                                document_type: data?.value,
                              });
                            }}
                            focus={
                              !!(
                                typeof formError === "object" &&
                                formError?.document_type
                              )
                            }
                            error={
                              !!(typeof formError === "object")
                                ? formError?.document_type
                                : ""
                            }
                            value={documentypeList?.find(
                              (i) => i?.value === documentDetails?.document_type
                            )}
                            ref={typeRef}
                            enableSearch={true}
                            // onEnterSelect={() => Ref.current.focus()}
                          />
                        </div>
                        <div className="_divForth">
                          <CommonInput
                            type="text"
                            placeholder="Enter Number"
                            label="Document Number"
                            name="document_number"
                            value={documentDetails?.document_number}
                            focus={
                              !!(
                                typeof formError === "object" &&
                                formError?.document_number
                              )
                            }
                            error={
                              !!(typeof formError === "object")
                                ? formError?.document_number
                                : ""
                            }
                            onChange={({ target: { name, value } }) =>
                              setDocumentDetails({
                                ...documentDetails,
                                document_number: value,
                              })
                            }
                            ref={numberRef}
                            // onKeyDown={(e) => handleKey(e, null)}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bottomFix">
        <button
          className="_addComm_btn"
          type="button"
          onClick={() => handleSubmit()}
          disabled={isLoading}
        >
          {isLoading ? <BasicExample /> : "Add Room Booking"}
        </button>
      </div>
    </div>
  );
};

export default CreateRoomBooking;
