import {
  createMasterEvent,
  getSingleMasterEvent,
  updateMasterEvent,
  uploadFiles,
} from "_services";
import { CommonDate, CommonInput, CommonModal, CustomSelect } from "components";
import { createEvent } from "features/masterService";
import { errorSchema, utils } from "helper";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import "../../assets/css/modal.css";
import MobileCamera from "modals/MobileCamera";

const UploadDocument = (props) => {
  const documentypeList = [
    { label: "Aadhar Card", value: 1 },
    { label: "Pan Card", value: 2 },
    { label: "Voter ID", value: 3 },
    { label: "Driving License", value: 4 },
    { label: "Passport", value: 5 },
  ];

  const [form, setForm] = useState({
    document_type: "",
    document_file: "",
    document_number: "",
  });

  const [initialForm,setInitialForm] = useState({
    document_type: "",
    document_file: "",
    document_number: "",
  })

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const typeRef = useRef();
  const fileRef = useRef();
  const numberRef = useRef();
  const [formError, setFormError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.uploadFileSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const handleSubmit = async () => {
    const validationResult = await utils?.checkFormError(
      form,
      errorSchema.uploadFileSchema
    );


    const formData = new FormData();
    Object.keys(form).forEach((key) => {
      // Only add the field to formData if it has changed
      if (form[key] !== initialForm[key]) {
        formData.append(key, form[key]);
      }
    });

   

    if (validationResult === true) {
      try {
        setIsLoading(true)
        if (props?.addressId) {
          const res = await uploadFiles({
            payload:formData,
            id: props?.addressId,
          });
          if (res.status === 200) {
            setIsLoading(false)
            toast.success(res.message);
            props?.closeModal();
            props?.callBackAddressData();
          }else{
              toast.error(res.error);
            }
        } 
          } catch (error) {
            setIsLoading(false)
          }
    } else {
      setFormError(validationResult);
    }
  };

  const objectBid = {
    ...props,
    onSubmit: handleSubmit,
    disabled: isLoading || props?.eventView,
    isLoadding: isLoading,
  };

  const handleKey = (e, nextInputRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextInputRef) {
        nextInputRef.current.focus();
      } else {
        handleSubmit(e);
      }
    } else if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
        const validImageTypes = ["image/jpeg", "image/png", "image/gif", "image/jpg"];
      if (validImageTypes.includes(file.type)) {
        console.log("Captured image file:", file);
        handleOnChange("document_file", file);
        setFormError({
          ...formError,
          document_file: "",
        });
      } else {
        setFormError({
          ...formError,
          document_file: "Please upload only image files (JPG, JPEG, PNG, GIF).",
        });
      }
    }
  };

  const handleImageCapture = (file) => {
  
    setForm((pre)=>({...pre,document_file:file}))
  };

  return (
    <>
      <CommonModal {...objectBid}>
        <div className="_plcMod_body" style={{ padding: "20px" }}>
          <div className="flxInr">
            <div className="singleDiv">
              <CustomSelect
                placeholder="Select Document Type"
                label="Document Type"
                options={documentypeList}
                onSelect={(data) => {
                  handleOnChange("document_type", data.value);
                }}
                focus={
                  !!(typeof formError === "object" && formError?.document_type)
                }
                error={
                  !!(typeof formError === "object")
                    ? formError?.document_type
                    : ""
                }
                value={documentypeList?.find(
                  (i) => i?.value === form?.document_type
                )}
                ref={typeRef}
                enableSearch={true}
                // onEnterSelect={() => Ref.current.focus()}
              />
            </div>

            <div className="singleDiv">
              
                <CommonInput
                  type="file"
                  onChange={handleFileChange}
                  accept=".jpeg, .png,.gif,.jpg"
                  error={formError?.file}
                />
             <div  style={{display:"flex",flexDirection:"column",alignItems:"center",gap:"5px",marginTop:"10px"}}>
             <h6>Or</h6>
             <MobileCamera
             uploadUrl="https://example.com/upload" // Replace with your actual API endpoint
             onCapture={handleImageCapture}
            //  onUpload={handleImageUpload}
             />
              {/* <button className="_addComm_btn _builtyPrintBtn" >
                Camera

              </button> */}
             </div>
             
            </div>
           
           
            <div className="singleDiv">
              <CommonInput
                type="text"
                placeholder="Enter Number"
                label="Document Number"
                name="document_number"
                value={form?.document_number}
                focus={
                  !!(
                    typeof formError === "object" && formError?.document_number
                  )
                }
                error={
                  !!(typeof formError === "object")
                    ? formError?.document_number
                    : ""
                }
                onChange={({ target: { name, value } }) =>
                  handleOnChange(name, value)
                }
                ref={numberRef}
                onKeyDown={(e) => handleKey(e, null)}
              />
            </div>
            <div className="singleDiv"></div>
          </div>
        </div>
      </CommonModal>
      <div className="offBack_drop"></div>
    </>
  );
};

export default UploadDocument;
