import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

const CommonPagination = ({ 
  totalItems, 
  itemsPerPage, 
  handlePrevPage,
  handleNextPage
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  return (
    <ul className="pagination">
      {/* Previous Button */}
      <li className={`page-item ${totalPages === 1 ? 'disabled' : ''}`}>
        <a
          className="page-link _avPointer"
          onClick={() => handlePrevPage()}
          aria-disabled={totalPages === 1}
          style={{ fontSize:"18px",paddingRight:"15px",paddingLeft:"10px"}}
        >
          <IoChevronBack />
          Prev
        </a>
      </li>

      {/* Next Button */}
      <li className={`page-item ${totalPages === 1 ? 'disabled' : ''}`}>
        <a
          className="page-link _avPointer"
          onClick={() => handleNextPage()}
          aria-disabled={totalPages === 1}
          style={{ fontSize:"18px",paddingLeft:"15px",paddingRight:"10px"}}
        >
        
          Next
          <IoChevronForward />
        </a>
      </li>
    </ul>
  );
};

export default CommonPagination;
