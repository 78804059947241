import React, { useEffect, useState } from "react";
import UserContext from "./UserContext";
import { StorageServices } from "utils";

const UserContextProvider = ({ children }) => {
  const [token, setToken] = useState(() => localStorage.getItem("token"));
  const [masterData, setMasterData] = useState(null);
  const [isSidebar, setToggleSidebar] = useState(false);

  return (
    <UserContext.Provider
      value={{
        masterData,
        setMasterData,
        isSidebar,
        setToggleSidebar,
        token,
        setToken,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
