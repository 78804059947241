import { UserContext } from "context";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { StorageServices } from "utils";

const PrivateRoute = ({ element }) => {
    const navigate = useNavigate();
    const { token, setShowAddAcountModal, setShowCreateCompanyModal } = useContext(UserContext)
    const { loading, error, userData, data } = useSelector((state) => state.auth);

    let isAuthenticated = StorageServices.getToken()
  
    useEffect(() => {
        if (!isAuthenticated) {
            navigate("/");
        }
       
    }, [navigate, isAuthenticated])

    return isAuthenticated
        ? element
        : <Navigate to="/" />;
}

export default PrivateRoute