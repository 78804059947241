const API = {
  // Auth
  
  COMMON_DROPDOWN: `common/`,
  // Main Dasboard
  GET_DASHBOARD_COUNT: `dashboard/get_dashboard_count`,

  ///////Other Project

  LOGIN_HOST:`/account/admin-login`,
  EVENT:`master/event`,
  ROOM:`master/room`,
  FLOOR:`master/floor`,

  GUEST:`account/user`,
  ROOM_BOOING:`booking/room`,
  ROOM_AVL:`/master/room/available_rooms`,
  KEY_REQUEST:`/booking/room-key-request`

};

export default {
  ...API,
};
