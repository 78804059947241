// write constant string

export const constant = {
  errorMessage: {
    require: "field is required",
    string: "value must be string",
    email: "please enter valid email",
    password: "Password must be at least 6 alphanumeric characters",
  },
  authModalType: {
    LoginSignUp: "LoginSignUp",
    Login: "Login",
    Signup: "Signup",
    Forgot: "Forgot",
    ForgotConfirmPsw: "ForgotConfirmPsw",
    ForgotOtpVerify: "ForgotOtpVerify",
    Intrest: "Intrest",
    UploadProfile: "UploadProfile",
    OtpVerification: "OtpVerification",
    SelectPersonaModal: "SelectPersonaModal",
    SelectTagsModal: "SelectTagsModal",
  },
  monthList: [
    { label: "January", value: "01" },
    { label: "February", value: "02" },
    { label: "March", value: "03" },
    { label: "April", value: "04" },
    { label: "May", value: "05" },
    { label: "June", value: "06" },
    { label: "July", value: "07" },
    { label: "August", value: "08" },
    { label: "September", value: "09" },
    { label: "October", value: "10" },
    { label: "November", value: "11" },
    { label: "December", value: "12" },
  ],
  dayList: [
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
    { label: "31", value: "31" },
  ],
  evenDayList: [
    { label: "01", value: "01" },
    { label: "02", value: "02" },
    { label: "03", value: "03" },
    { label: "04", value: "04" },
    { label: "05", value: "05" },
    { label: "06", value: "06" },
    { label: "07", value: "07" },
    { label: "08", value: "08" },
    { label: "09", value: "09" },
    { label: "10", value: "10" },
    { label: "11", value: "11" },
    { label: "12", value: "12" },
    { label: "13", value: "13" },
    { label: "14", value: "14" },
    { label: "15", value: "15" },
    { label: "16", value: "16" },
    { label: "17", value: "17" },
    { label: "18", value: "18" },
    { label: "19", value: "19" },
    { label: "20", value: "20" },
    { label: "21", value: "21" },
    { label: "22", value: "22" },
    { label: "23", value: "23" },
    { label: "24", value: "24" },
    { label: "25", value: "25" },
    { label: "26", value: "26" },
    { label: "27", value: "27" },
    { label: "28", value: "28" },
    { label: "29", value: "29" },
    { label: "30", value: "30" },
  ],
  genderArray: [
    { label: "Male", value: "1" },
    { label: "Female", value: "2" },
    // { label: "Other", value: "3" },
  ],
  communityTypeArray: [
    {
      label: "Public",
      value: "1",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    {
      label: "Private",
      value: "2",
      description:
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.",
    },
    // { label: "Other", value: "3" },
  ],
  followArray: [{ label: "On", value: "on" }],
  privacySafety: [{ label: "Off", value: "off" }],
  tags_PostModal: [
    { label: "twitter +", value: "1" },
    { label: "elon musk +", value: "2" },
    { label: "deadpool +", value: "3" },
    { label: "design +", value: "4" },
    { label: "product +", value: "5" },
    { label: "deadpool +", value: "6" },
    { label: "image +", value: "7" },
  ],
  followingFollowersTabs: [
    {
      label: "Following",
      value: "following",
    },
    { label: "Followers", value: "followers" },
  ],
}
