import { createMasterEvent, createMasterFloor, getSingleMasterFloor, updateMasterFloor } from "_services";
import {
  CommonDate,
  CommonDatePicker,
  CommonInput,
  CommonModal,
} from "components";
import { createEvent } from "features/masterService";
import { errorSchema, utils } from "helper";
import React, { useEffect, useMemo, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const AddFloorModal = (props) => {
  const [form, setForm] = useState({
    name: "",
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nameRef = useRef()
  const startRef = useRef()
  const endRef = useRef()
  const [formError, setFormError] = useState("");
  const [isLoading,setIsLoading] = useState(false)

  useEffect(() => {
    if (props?.addressId) {
      getFloorDetails(props?.addressId);
    }
  }, [props?.addressId]);

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, []);

  const getFloorDetails = async (id) => {
    try {
      const res = await getSingleMasterFloor(id);
   
      if (res?.status === 200) {
        setForm({
          ...form,
          name: res?.results?.name,
        });
      }
    } catch (error) {}
  };

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.floorSchema
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  const handleSubmit = async () => {
    const validationResult = await utils?.checkFormError(
      form,
      errorSchema.floorSchema
    );

    if (validationResult === true) {
      setIsLoading(true)
        try {
          if (props?.addressId) {
            const res = await updateMasterFloor({
              payload:form,
              id: props?.addressId,
            });
  
      
            if (res.status === 200) {
              setIsLoading(false)
              toast.success(res.message);
              props?.closeModal();
              props?.callback();
            }else{
              toast.error(res.error);
            }
          } else{
            const res = await createMasterFloor(form);

            if (res.status === 201) {
              setIsLoading(false)
              toast.success(res.message);
              props?.closeModal();
              props?.callback();
            }else{
              toast.error(res.error);
            }
          }
          } catch (error) {
            setIsLoading(false)
          }
      }else{
        setFormError(validationResult)
      }
    
  };

  const objectBid = {
    ...props,
    onSubmit: handleSubmit,
    disabled:isLoading || props?.eventView,
    isLoadding:isLoading
  };

  const handleKey = (e, nextInputRef) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (nextInputRef) {
        nextInputRef.current.focus(); 
      } else {
        handleSubmit(e);
      }
    }
  };

  return (
    <>
      <CommonModal {...objectBid}>
        <div className="_plcMod_body" style={{ padding: "20px" }}>
          <div className="flxInr">
            <div className="singleDiv">
              <CommonInput
                type="text"
                label="Name"
                placeholder="Enter Name"
                name="name"
                value={form?.name}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name, value);
                }}
                focus={!!formError?.name}
                error={formError.name}
                ref={nameRef}
                onKeyDown={(e) => handleKey(e, null)}
                disabled={props?.eventView}
              />
            </div>
          </div>
        </div>
      </CommonModal>
      <div className="offBack_drop"></div>
    </>
  );
};

export default AddFloorModal;
